import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import { BsPencil } from 'react-icons/bs';
import { editorDefaultEntries } from '../../languages/defaultEntries';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import LanguageDropDown from './LanguageDropDown';
import { CancelButton, SaveButton } from '../buttons/Buttons';

const WEBSITE_URL = '/website';

export default function Content(props) {
  const title = props.title;
  const { username } = useAuth();
  const { i18n, t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState('');
  const [originalContent, setOriginalContent] = useState('');
  const [editing, setEditing] = useState(false);
  const [isContentEmpty, setIsContentEmpty] = useState(false);
  const [art, setArt] = useState(i18n.language);


  useEffect(() => {
    setArt(i18n.language)
    const getContent = async () => {
      try {
        const response = await axios.get(`${WEBSITE_URL}`, {
          params: { username, art, title },
        });
        if (response.data.entry) {
          setContent(response.data.entry.content);
          setOriginalContent(response.data.entry.content);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getContent();
  }, [art, title, username, i18n.language]);

  // useEffect(() => {
  //   const getContent = async () => {
  //     try {
  //       const response = await axios.get(`${WEBSITE_URL}`, {
  //         params: { username, art, title },
  //       });
  //       if (response.data.entry) {
  //         setContent(response.data.entry.content);
  //         setOriginalContent(response.data.entry.content);
  //       }
  //       setLoading(false);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   getContent();
  // }, [art, title, username]);

  const handleEditClick = () => {
    setEditing(true);
  };

  const saveContent = async () => {
    try {
      await axios.put(WEBSITE_URL, {
        username,
        art,
        title,
        content,
      });
      setOriginalContent(content);
      setEditing(false);
    } catch (err) {
      console.log(err);
    }
  }

  const handleSaveClick = (event) => {
    event.preventDefault();
    if (isContentEmpty) {
      alert(t("warnings.paragraph_empty"));
      return;
    }
    saveContent();
  };

  const handleCancelClick = () => {
    setContent(originalContent);
    setEditing(false);
  };

  const handleContentChange = (event) => {
    const newContent = event.target.value;
    setContent(newContent);
    setIsContentEmpty(newContent.trim() === '');
  };

  return (
    <div>
      {editing ?
        (<div>
          <div className='text-input-container'>
            <textarea
              spellcheck="false"
              autocomplete="off"
              className={props.styling}
              style={{ border: "1px solid gray", borderRadius: "5px", width: "100%", minHeight: "150px" }}
              value={content === editorDefaultEntries.paragraph ? '' : content}
              onChange={handleContentChange}
            />
            {isContentEmpty && <p style={{ color: 'red' }}>{t("warnings.paragraph_empty")}</p>}
          </div>
          <div>
            <div className='edit-container'>
              <SaveButton
                buttonText={t("button.save")}
                onClick={(event) => handleSaveClick(event)}
                type='submit'
              />
              <CancelButton
                buttonText={t("button.cancel")}
                onClick={handleCancelClick}
              />
              <div>
                <LanguageDropDown
                  setArt={setArt}
                  art={i18n.language}
                />
              </div>
            </div>
          </div>
        </div>) : (
          !loading ? (<div>
            <p className={content === editorDefaultEntries.paragraph ? 'default-entry-paragraph' : 'ws-pre-wrap'} onDoubleClick={handleEditClick}>
              <span className='edit-text' onClick={handleEditClick}>
                <BsPencil size="25px" />
              </span>
              {content === editorDefaultEntries.paragraph ? t("editor_default_display.paragraph") : content}
            </p>
          </div>) : (
            <div>
              <p>
                <span className='edit-text' onClick={handleEditClick}>
                  <BsPencil size="25px" />
                </span>
                Loading...
              </p>
            </div>
          )
        )
      }
    </div>
  );
}
