import React, { useState } from "react";
import { Document, Page } from "react-pdf";

export default function EnLegalConditions() {
  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  return (
    <div>
      <form>
        <label htmlFor="fileInput">Upload PDF file:</label>
        <input type="file" id="fileInput" onChange={handleFileChange} />
        <br />
      </form>
      {file && (
        <div>
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <p>
            Page {pageNumber} of {numPages}
          </p>
          <button onClick={() => handlePageChange(pageNumber - 1)}>
            Previous
          </button>
          <button onClick={() => handlePageChange(pageNumber + 1)}>
            Next
          </button>
        </div>
      )}
    </div>
  );
}



