import { Box, NavLink, SimpleGrid } from '@mantine/core';
import { Icon } from '@iconify/react';
import FileInputForm from './FileInputForm';
import { useTranslation } from 'react-i18next';

export default function Equipment() {
    const { t } = useTranslation();

    return (
        <div className="stripe">
            <SimpleGrid
                cols={3}
                spacing="lg"
                breakpoints={[
                    { maxWidth: 'md', cols: 2, spacing: 'md' },
                    { maxWidth: 'sm', cols: 1, spacing: 'sm' },
                ]}>

                <Box w={240}>
                    <NavLink label={t('equipment.titles.kitchen')} icon={<Icon icon="fa6-solid:kitchen-set" />} childrenOffset={28}>
                        <FileInputForm title={'kitchen'} array={t('equipment.kitchen', { returnObjects: true })} />
                        <NavLink label={t('equipment.titles.kitchen_extra')} childrenOffset={28}>
                            <FileInputForm title={'kitchen_extra'} array={t('equipment.kitchen_extra', { returnObjects: true })} />
                        </NavLink>
                    </NavLink>
                </Box>

                <Box w={240}>
                    <NavLink label={t('equipment.titles.bathroom')} icon={<Icon icon="mdi:bathroom" />} childrenOffset={28}>
                        <FileInputForm title={'bathroom'} array={t('equipment.bathroom', { returnObjects: true })} />
                    </NavLink>
                </Box>

                <Box w={240}>
                    <NavLink label={t('equipment.titles.heating')} icon={<Icon icon="iconoir:heating" />} childrenOffset={28}>
                        <FileInputForm title={'heating'} array={t('equipment.heating', { returnObjects: true })} />
                    </NavLink>
                </Box>

                <Box w={240}>
                    <NavLink label={t('equipment.titles.living_room')} icon={<Icon icon="mdi:living-room" />} childrenOffset={28}>
                        <FileInputForm title={'livingroom'} array={t('equipment.living_room', { returnObjects: true })} />
                    </NavLink>
                </Box>

                <Box w={240}>
                    <NavLink label={t('equipment.titles.bedroom')} icon={<Icon icon="mdi:bedroom-outline" />} childrenOffset={28}>
                        <FileInputForm title={'bedroom'} array={t('equipment.bedroom', { returnObjects: true })} />
                    </NavLink>
                </Box>

                <Box w={240}>
                    <NavLink label={t('equipment.titles.entertainment')} icon={<Icon icon="ic:outline-directions-bike" />} childrenOffset={28}>
                        <FileInputForm title={'entertainment'} array={t('equipment.entertainment', { returnObjects: true })} />
                        <NavLink label={t('equipment.titles.bike')} childrenOffset={28}>
                            <FileInputForm title={'bikes'} array={t('equipment.bike', { returnObjects: true })} />
                        </NavLink>
                    </NavLink>
                </Box>

                <Box w={240}>
                    <NavLink label={t('equipment.titles.children')} icon={<Icon icon="mdi:children-toy" />} childrenOffset={28}>
                        <FileInputForm title={'children'} array={t('equipment.children', { returnObjects: true })} />
                    </NavLink>
                </Box>

                <Box w={240}>
                    <NavLink label={t('equipment.titles.safety')} icon={<Icon icon="foundation:safety-cone" />} childrenOffset={28}>
                        <FileInputForm title={'safety'} array={t('equipment.safety', { returnObjects: true })} />
                    </NavLink>
                </Box>

                <Box w={240}>
                    <NavLink label={t('equipment.titles.outside')} icon={<Icon icon="ph:tree-evergreen-fill" />} childrenOffset={28}>
                        <FileInputForm title={'outside'} array={t('equipment.outside', { returnObjects: true })} />
                    </NavLink>
                </Box>

                <Box w={240}>
                    <NavLink label={t('equipment.titles.internet')} icon={<Icon icon="material-symbols:wifi" />} childrenOffset={28}>
                        <FileInputForm title={'wifi'} array={t('equipment.internet', { returnObjects: true })} />
                    </NavLink>
                </Box>

                <Box w={240}>
                    <NavLink label={t('equipment.titles.desk')} icon={<Icon icon="mdi:desk-lamp" />} childrenOffset={28}>
                        <FileInputForm title={'desk'} array={t('equipment.desk', { returnObjects: true })} />
                    </NavLink>
                </Box>

                <Box w={240}>
                    <NavLink label={t('equipment.titles.washing')} icon={<Icon icon="icon-park-outline:washing-machine" />} childrenOffset={28}>
                        <FileInputForm title={'washing'} array={t('equipment.washing', { returnObjects: true })} />
                    </NavLink>
                </Box>

                <Box w={240}>
                    <NavLink label={t('equipment.titles.accessibility')} icon={<Icon icon="carbon:accessibility" />} childrenOffset={28}>
                        <FileInputForm title={'accessibility'} array={t('equipment.accessibility', { returnObjects: true })} />
                    </NavLink>
                </Box>

                <Box w={240}>
                    <NavLink label={t('equipment.titles.parking')} icon={<Icon icon="ic:twotone-miscellaneous-services" />} childrenOffset={28}>
                        <FileInputForm title={'parking'} array={t('equipment.parking', { returnObjects: true })} />
                    </NavLink>
                </Box>

                <Box w={240}>
                    <NavLink label={t('equipment.titles.entree')} icon={<Icon icon="mdi:hallway" />} childrenOffset={28}>
                        <FileInputForm title={'entree'} array={t('equipment.entree', { returnObjects: true })} />
                    </NavLink>
                </Box>

            </SimpleGrid>
        </div>
    );
}
