import React, { lazy, Suspense, useState, useEffect } from "react";
import FetchContent from '../dynamic/fetchContent.jsx';
import axios from '../../api/axios.js';
import useAuth from '../../hooks/useAuth.js';
import {
    contactDefaultDisplay, contactDefaultEntries,
    accommodationFormDefaultDisplay, accFormDefaultEntries,
} from "../../languages/defaultEntries.js";
import FetchCarouselContent from "../dynamic/fetchCarouselContent.jsx";
import Equipment from "./Equipment.jsx";
import Reviewsjson from './Reviewsjson.jsx';
import ReviewsjsonEdit from "./ReviewsjsonEdit.jsx";
import Services from "./Services.jsx";
import Content from "../dynamic/Content.jsx";
import ImageContent from "../dynamic/ImageContent.jsx";
import CarouselContent from "./CarouselContent.js";
import Map from "../dynamic/Map";
import ReviewLinks from "./ReviewLinks.jsx";
import { useTranslation } from "react-i18next";

const WEBSITE_URL = '/dashboard/key';
const FetchImage = lazy(() => import('../dynamic/fetchImage.jsx'))
const WEBSITE_URL_CONTACT = '/contact';

export default function Home() {
    const { username } = useAuth();

    const [accomodationType, setAccomodationType] = useState('');
    const [guests, setGuests] = useState(1);
    const [city, setCity] = useState('');
    const [nameAccommodation, setNameAccommodation] = useState('');
    const [meterSquared, setMeterSquared] = useState(0); // default value [m²]
    const [containsDefault, setContainsDefault] = useState(true);
    const [loadComplete, setLoadComplete] = useState(true);

    const { auth } = useAuth();
    const { t } = useTranslation();

    const accDefaultDisplay = accommodationFormDefaultDisplay;
    const [propertyType, setPropertyType] = useState('');

    useEffect(() => {
        if (accomodationType !== accFormDefaultEntries.type &&
            guests !== accFormDefaultEntries.guests &&
            city !== contactDefaultEntries.city &&
            nameAccommodation !== accFormDefaultEntries.nameAccommodation) {
            setContainsDefault(false);
        }
    }, [accomodationType, guests, city, nameAccommodation])

    useEffect(() => {
        const fetchContent = async () => {
            try {
                await axios.get(WEBSITE_URL, {
                    params: {
                        username: username,
                        key: 'accomodationType', // specify the column you want to retrieve
                    },
                }).then((res) => {
                    setAccomodationType(res?.data?.accomodationType);
                });
                await axios.get(WEBSITE_URL, {
                    params: {
                        username: username,
                        key: 'guests', // specify the column you want to retrieve
                    },
                }).then((res) => {
                    setGuests(res?.data?.guests);
                });
                await axios.get(WEBSITE_URL, {
                    params: {
                        username: username,
                        key: 'nameAccommodation', // specify the column you want to retrieve
                    },
                }).then((res) => {
                    setNameAccommodation(res?.data?.nameAccommodation);
                });
                await axios.get(WEBSITE_URL, {
                    params: {
                        username: username,
                        key: 'meterSquared', // specify the column you want to retrieve
                    },
                }).then((res) => {
                    setMeterSquared(res?.data?.meterSquared);
                });
                await axios.get(WEBSITE_URL_CONTACT, {
                    params: {
                        username: username,
                        art: "nl",
                        title: "contact",
                    },
                }).then((res) => {
                    setCity(res?.data?.entry?.city);
                });
            } catch (error) {
                console.error(error);
            }
        };
        fetchContent();
    }, [username]);

    useEffect(() => {
        setLoadComplete(!!accomodationType && !!guests && !!nameAccommodation && !!city);
    }, [loadComplete, accomodationType, guests, nameAccommodation, city]);
    // display empty string if db contains default entry    
    useEffect(() => {
        setPropertyType((accomodationType === accFormDefaultEntries.type || !loadComplete) ? (
            ''
        ) : (
            t("codes.acc_types." + accomodationType)
        ));
    }, [accomodationType, t, loadComplete])

    return (
        <div className='default'>

            <div className='image-content'>
                <Suspense fallback={<h1>Still Loading…</h1>}>
                    {(!auth?.role ?
                        <FetchImage title="home" type="image-home" />
                        :
                        <ImageContent title="home" username={username} type="image-home" />
                    )}
                </Suspense>
            </div>

            {(loadComplete) && (
                <div>
                    {(containsDefault && Boolean(auth?.role)) && <p className="hint-msg">{t("hints.contains_default")}</p>}

                    <div className="stripe container ws-pre-wrap">
                        <div className="p">
                            <h1> {nameAccommodation === accFormDefaultEntries.nameAccommodation ? accDefaultDisplay.nameAccommodation : nameAccommodation} </h1>
                            <h2> {propertyType} - {t("home_page.upto")}{guests === accFormDefaultEntries.guests ? accDefaultDisplay.guests : guests} {t("home_page.guests")} - {t("home_page.in")} {city === contactDefaultEntries.city ? contactDefaultDisplay.city : city} ({meterSquared} m²)</h2>
                            <hr></hr>
                        </div>
                        {(!auth?.role ?
                            <div className="p">
                                <FetchContent title="p-accommodation" />
                            </div> :
                            <div className="p">
                                <Content title={"p-accommodation"} styling="p" />
                            </div>
                        )}
                    </div>
                    <div className="stripe stripe-carousel">
                        <Suspense fallback={<h1>Still Loading…</h1>}>
                            {(!auth?.role ?
                                <FetchCarouselContent title="home" username={username} />
                                :
                                <CarouselContent title="home" username={username} maxImages={15} />
                            )}
                        </Suspense>
                    </div>

                    <div className="stripe">
                        <div className="container">
                            <h2> {t("home_page.equipment")} </h2>
                            <Equipment />
                        </div>
                    </div>

                    <div className="stripe-services">
                        <Services />
                    </div>

                    <div>
                        {(!auth?.role ?
                            <Map /> :
                            <Map adminUser={true} />)}
                    </div>

                    <div className="p stripe-reviews">
                        <div className="container text-center">
                            {(!auth?.role ?
                                <Reviewsjson />
                                :
                                <ReviewsjsonEdit />
                            )}
                        </div>
                    </div>
                    <div className="stripe">
                        <ReviewLinks />
                    </div>
                  
                </div>
            )}
        </div>
    )
}
