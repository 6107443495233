import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import cloudinaryConfig from '../../config/cloudinary.config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

// const connectionErrorImage = "https://picsum.photos/4000";
const WEBSITE_URL = '/pdf_url';

export default function PdfUpload(props) {

    const [selectedFile, setSelectedFile] = useState(null);
    const [newPdfUrl, setNewPdfUrl] = useState(null);
    const [pdfUrl, setPdfUrl] = useState("");
    const [publicID, setPublicID] = useState("");
    const [succesUpload, setSuccesUpload] = useState(false);

    const { username, auth } = useAuth();
    const { t } = useTranslation();

    // load on render
    useEffect(() => {
        const title = props.title;
        const getContent = async () => {
            await axios.get(`${WEBSITE_URL}`, {
                params: { username, title },
            }).then((res) => {
                setPdfUrl(res.data);
            }).catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });

        };
        getContent();
    }, [username, props.title]);

    // update image url in database
    useEffect(() => {
        const update = async () => {
            if (newPdfUrl && username && props.title) {
                await axios.put(`${WEBSITE_URL}`, {
                    username: username,
                    title: props.title,
                    public_id: publicID,
                    url: newPdfUrl
                })
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((err) => {
                        console.error(err);
                    })
            }
        }
        update();
    }, [newPdfUrl, props.title, username, publicID]);

    // upload to cloud on file input change
    useEffect(() => {
        const upload = async () => {
            // get signature for upload
            const signResponse = await axios.get('/signuploadform', {
                params: {
                    folder: username,
                    publicID: props.title
                }
            });

            const signData = signResponse.data;
            const url = cloudinaryConfig.BASE_URL + signData.cloudName + cloudinaryConfig.UPLOAD_SUFFIX;
            // populate form for upload
            const formData = new FormData();
            formData.append("api_key", signData.apiKey);
            formData.append("cloud_name", signData.cloudName);
            formData.append("file", selectedFile);
            formData.append("folder", username);
            formData.append("public_id", props.title);
            formData.append("signature", signData.signature);
            formData.append("timestamp", signData.timestamp);


            // upload to cloudinary
            if (url && signData && selectedFile) {
                await axios.post(url, formData)
                    .then((res) => {
                        setNewPdfUrl(res.data.secure_url);
                        setPublicID(res.data.public_id);
                        setSuccesUpload(true);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }
        upload();
    }, [selectedFile, props.title, username]);


    async function handleFileInputChange(event) {
        try {
            setSelectedFile(event.target.files[0]);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='upload-pdf'>
            {auth?.role ? (
                <>
                <input type="file" onChange={handleFileInputChange} />
                {succesUpload && <p> Upload successful  </p> }
</>
            ) : (
                <div>
                    <FontAwesomeIcon icon={faLightbulb} />
                    <a className='suggestions-text' href={pdfUrl}>{t("suggestions_page.pdf-look")}</a>
                </div>

            )}
        </div>
    );
}
