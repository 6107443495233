import React, { useState, useEffect } from 'react';
import useAuth from "../../hooks/useAuth";
import { useTranslation } from 'react-i18next';
import axios from "../../api/axios";
import PdfUpload from "../dynamic/PdfUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';

const WEBSITE_URL = '/pdf_url';

export default function TandC() {

    const title = "TandC";
    const { username } = useAuth();
    const { t } = useTranslation();
    const [TandCUrl, setTandCUrl] = useState(null);

    useEffect(() => {
        async function fetch() {
            await axios.get(`${WEBSITE_URL}`, {
                params: { username, title },
            }).then((res) => {
                setTandCUrl(res.data)
            }).catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
        }
        fetch()
    }, [username])

    return (
        <form className="review-form">
            <h3> {t("dashboard_page.TC.title")}</h3>
            <div className="form-row form-label">
                {t("dashboard_page.TC.upload")}
                <PdfUpload
                    username={username}
                    title={title}
                />
            </div>
            {TandCUrl &&
                <>
                    <FontAwesomeIcon icon={faLightbulb} />
                    <a className='suggestions-text' href={TandCUrl}>{t("dashboard_page.TC.look")}</a>
                </>
            }
        </form>
    );
}
