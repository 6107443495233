import './buttons.css'
import { RiSave3Fill, RiImageEditFill } from 'react-icons/ri'
import { MdCancel, MdDeleteForever, MdAddToPhotos } from 'react-icons/md'
import { BsPencil, BsSearch, BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { BiAddToQueue } from 'react-icons/bi'
import { TbReload } from 'react-icons/tb'

export function ExternalLinkButton({ buttonText }) {
    return (
        <button
            className="extlink"
        >{buttonText}</button>
    )
}

export function SaveButton({ buttonText, onClick, enabled = true, type = "button" }) {
    return (
        <button
            className={enabled ? "save" : " save bth-invalid"}
            onClick={onClick}
            type={type}
            disabled={!enabled}
        >
            <span className='save-cancel'><RiSave3Fill /></span>
            {buttonText}
        </button>
    )
}

export function CancelButton({ buttonText, onClick, type = "button" }) {
    return (
        <button
            className='cancel'
            onClick={onClick}
            type={type}>
            <span className='save-cancel'>
                <MdCancel />
            </span>
            {buttonText}
        </button>
    )
}

export function EditButtonLarge({ buttonText, onClick }) {
    return (
        <button
            className="edit"
            onClick={onClick}>
            <span className='save-cancel'>
                <BsPencil />
            </span>
            {buttonText}
        </button>
    )
}

export function DeleteButton({ buttonText, onClick, id = 0, type = "button" }) {
    return (
        <button
            className="cancel"
            onClick={onClick}
            id={id}
            type={type}>
            <span className='save-cancel'>
                <MdDeleteForever />
            </span>
            {buttonText}
        </button>
    )
}

export function AddButton({ buttonText, onClick, type = "button" }) {
    return (
        <button
            className="save"
            onClick={onClick}
            type={type}>
            <span className='save-cancel'>
                <BiAddToQueue />
            </span>
            {buttonText}
        </button>
    )
}

// button with arrow to the left
export function LeftButton({ buttonText, onClick, type = "button" }) {
    return (
        <button
            className="save"
            onClick={onClick}
            type={type}>
            <span className='save-cancel'>
                <BsArrowLeft />
            </span>
            {buttonText}
        </button>
    )
}

// button with arrow to the left
export function RightButton({ buttonText, onClick, type = "button" }) {
    return (
        <button
            className="save"
            onClick={onClick}
            type={type}>
            <span className='save-cancel'>
                <BsArrowRight />
            </span>
            {buttonText}
        </button>
    )
}

export function EditImageButton({ buttonText, onClick, small = false }) {
    return (
        <button
            className='edit-image'
            onClick={onClick}>
            <span className='edit-image'>
                <RiImageEditFill />
            </span>
            {!small && buttonText}
        </button>
    )
}

export function SearchButton({ buttonText, onClick, type = "button" }) {
    return (
        <button
            type={type}
            className="edit"
            style={{ marginTop: "10px", marginBottom: "10px", paddingBottom: "10px" }}
            onClick={onClick}
        >
            <span className='save-cancel'>
                <BsSearch />
            </span>
            {buttonText}
        </button>
    )
}

export function ReloadButton({ buttonText, onClick, type = "button" }) {
    return (
        <button
            type={type}
            className="edit"
            style={{ marginTop: "10px", marginBottom: "10px", paddingBottom: "10px" }}
            onClick={onClick}
        >
            <span className='save-cancel'>
                <TbReload />
            </span>
            {buttonText}
        </button>
    )
}

export function LoginButton({ buttonText, onClick, href }) {
    return (
        <button
            href={href}
            className="login signOut"
            onClick={onClick}
        >
            <a href={href}>
                {buttonText}
            </a>
            <span aria-hidden="true"> &rarr;</span>
        </button>
    )
}

export function LogoutButton({ buttonText, onClick, href }) {
    return (
        <button
            href={href}
            className="login signOut"
            onClick={onClick}
        >
            <a href={href}>
                {buttonText}
            </a>
            <span aria-hidden="true">&rarr;</span>
        </button>
    )
}

export function AddImageButton({ buttonText, disable, onClick }) {
    return (
        <button
            className='add'
            disabled={disable}
            onClick={onClick}>
            <span className='edit-image'>
                <MdAddToPhotos />
            </span>
            {buttonText}
        </button>
    )
}