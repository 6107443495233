import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils, faSuitcase, faCalendarDays, faBroom } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';

const WEBSITE_URL = '/dashboard/key';


const Services = () => {
    const { username } = useAuth();
    const { t } = useTranslation();
    const [cleaningService, setCleaningService] = useState("-1");
    const [luggageService, setLuggageService] = useState("-1");
    const [breakfastService, setBreakfastService] = useState("-1");
    const [longStay, setLongStay] = useState("-1");


    useEffect(() => {
        const fetchContent = async () => {
            try {
                const responseCleaning = await axios.get(WEBSITE_URL, {
                    params: {
                        username: username,
                        key: 'cleaningService', // specify the column you want to retrieve
                    },
                },);
                const responseLuggage = await axios.get(WEBSITE_URL, {
                    params: {
                        username: username,
                        key: 'luggageService', // specify the column you want to retrieve
                    },
                },
                );
                const responseBreakfast = await axios.get(WEBSITE_URL, {
                    params: {
                        username: username,
                        key: 'breakfastService', // specify the column you want to retrieve
                    },
                },
                );
                const responseLongStay = await axios.get(WEBSITE_URL, {
                    params: {
                        username: username,
                        key: 'longStay', // specify the column you want to retrieve
                    },
                },
                );
                const content = responseCleaning.data.cleaningService;
                setCleaningService(content);
                const contentLuggage = responseLuggage.data.luggageService;
                setLuggageService(contentLuggage);
                setBreakfastService(responseBreakfast.data.breakfastService);
                setLongStay(responseLongStay.data.longStay);

            } catch (error) {
                console.error(error);
            }
        };

        fetchContent();
    }, [username]);

    return (
        <div>
            <h2 style={{ marginBottom: "30px" }}>
                {t("services.title")}
            </h2>
            <div className="dog-friendly-home p" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '50px' }}>

                {breakfastService !== '0' && (
                    <div style={{ textAlign: 'center' }}>
                        <h3><FontAwesomeIcon icon={faUtensils} /></h3>
                        <h3>{t("services.breakfast")}</h3>
                        {t("service_codes." + breakfastService)}
                    </div>
                )}
                {luggageService !== '0' && (
                    <div style={{ textAlign: 'center' }}>
                        <h3><FontAwesomeIcon icon={faSuitcase} /></h3>
                        <h3>{t("services.luggage")}</h3>
                        {t("service_codes." + luggageService)}
                    </div>
                )}
                {cleaningService !== '0' && (
                    <div style={{ textAlign: 'center' }}>
                        <h3><FontAwesomeIcon icon={faBroom} /></h3>
                        <h3>{t("services.cleaning")}</h3>
                        {t("service_codes." + cleaningService)}
                    </div>
                )}
                {longStay !== '0' && (
                    <div style={{ textAlign: 'center' }}>
                        <h3><FontAwesomeIcon icon={faCalendarDays} /></h3>
                        <h3>{t("services.long_term")}</h3>
                        {t("service_codes." + longStay)}
                    </div>
                )}

            </div>
        </div>
    );
};

export default Services;



