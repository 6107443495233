import Suggestion from "./Suggestion";
import axios from "../../api/axios";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import TagFilter from "./TagFilter";

const WEBSITE_URL = '/websitejson';

export default function Suggestions() {
    const title = "reviews-suggestions";
    const { username } = useAuth();
    const { i18n, t } = useTranslation();

    const [filterTags, setFilterTags] = useState([]);

    const [suggJSON, setSuggJSON] = useState(
        {
            "nl":
                [
                    {
                        "id": 0,
                        "title": "",
                        "intro": "",
                        "address": "",
                        "tel": "",
                        "weblink": "",
                        "tags": []
                    },
                    {
                        "id": 1,
                        "title": "",
                        "intro": "",
                        "address": "",
                        "tel": "",
                        "weblink": "",
                        "tags": []
                    }


                ],
            "en":
                [
                    {
                        "id": 0,
                        "title": "",
                        "intro": "",
                        "address": "",
                        "tel": "",
                        "weblink": "",
                        "tags": []
                    },
                    {
                        "id": 1,
                        "title": "",
                        "intro": "",
                        "address": "",
                        "tel": "",
                        "weblink": "",
                        "tags": []
                    }


                ]

        });
    // Get from DB
    useEffect(() => {
        async function fetchData() {
            // get suggestions as a json object
            await axios.get(`${WEBSITE_URL}`,
                {
                    params: {
                        username: username,
                        art: "nl",
                        title: title
                    },
                }).then((res) => {
                    setSuggJSON(JSON.parse(res?.data?.entry?.content))
                }).catch(function (err) {
                    if (err.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(err.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', err.message);
                    }
                });
        }
        fetchData();
    }, [username])

    return (
        <div>
            <h2>{t("suggestions_page.suggestions")}</h2>
            <TagFilter
                filterTags={filterTags}
                setFilterTags={setFilterTags}
            />
            <div className="suggestions-container">
                {suggJSON[i18n.language].map((suggestion) => {
                    return (<Suggestion suggestion={suggestion} filterTags={filterTags} setFilterTags={setFilterTags} key={suggestion.id} />)
                })}
            </div>
        </div>
    )
}