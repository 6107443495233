import { MultiSelect } from "@mantine/core"
import { useTranslation } from "react-i18next"

export default function TagFilter({ filterTags, setFilterTags }) {
    const { t } = useTranslation();

    return (
        <div className="filter-tags-container">
            <p className="filter-tags-label">{t("suggestions_page.filter_by")}</p>
            <MultiSelect
                placeholder={t("suggestions_page.tag-placeholder")}
                data={t("suggestions_page.tag-options", { returnObjects: true })}
                value={filterTags ? filterTags : []}
                onChange={setFilterTags}
                clearable
                searchable
            />
        </div>
    )
}