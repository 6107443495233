import React, { lazy, Suspense, useState, useEffect } from "react";
import './suggestions.css';
import FetchContent from "../dynamic/fetchContent";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import TourismOffice from "./TourismOffice";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import Content from "../dynamic/Content";
import PdfUpload from "../dynamic/PdfUpload";
import Suggestions from "./Suggestions";
import SuggestionsEdit from "./SuggestionsEdit";
import CarouselSuggEdit from "./CarouselSuggEdit";

const CarouselSugg = lazy(() => import('./CarouselSugg'))

const WEBSITE_URL = '/pdf_url';
const title = 'suggestions';

export default function SuggestionsMain() {

  const { username, auth } = useAuth();
  const { t } = useTranslation();
  const [suggestionsUrl, setSuggestionsUrl] = useState(null);

  useEffect(() => {
    async function fetch() {
      await axios.get(`${WEBSITE_URL}`, {
        params: { username, title },
      }).then((res) => {
        setSuggestionsUrl(res.data)
      }).catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
    }
    fetch()
  }, [username])

  return (
    <div className="default">
      <Suspense fallback={<h1>Still Loading…</h1>}>
        {(!auth?.role ?
          <CarouselSugg title="suggestions" username={username} type="image-about" />
          :
          <CarouselSuggEdit title="suggestions" username={username} type="image-about" maxImages={15} />
        )}
      </Suspense>
      <div className="stripe">
        <h1>{t("suggestions_page.suggestions")}</h1>
        <div className="container">
          <hr></hr>
        </div>
        <div className="container p">
          {(!auth?.role ?
            <FetchContent title="p-suggestions" />
            :
            <div className="p edit-sign ws-pre-wrap">
              <Content title={"p-suggestions"} styling="p" />
            </div>
          )}
        </div>
      </div>

      <TourismOffice />

      <div className="stripe stripe-suggestions">
        <div className="container">
          <div>
            {(!auth?.role ?
              <Suggestions />
              :
              <SuggestionsEdit />
            )}
          </div>
        </div>
      </div>

      {(!auth?.role ?
        <>
          {suggestionsUrl && <div className="stripe-suggestions ">
            <div className="suggestions-pdf-container">
              <FontAwesomeIcon icon={faLightbulb} />
              <a className='suggestions-text' href={suggestionsUrl}>{t("suggestions_page.recommendations")}</a>
            </div>
          </div>}
        </> :
        <div className="stripe-suggestions ">
          <div className="suggestions-pdf-container">
            <div className="upload-label">{t("suggestions_page.upload-pdf")}</div>
            <PdfUpload
              username={username}
              title={title}
            />
          </div>
        </div>
      )}
    </div>
  )
}
