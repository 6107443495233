//  ================= EN user guide default text ================== //
//  this is displayed to the user if the database contains default entries (as defined below in 'enContactDefaultEntries')
export const contactDefaultDisplay = {
    'name': '',
    'lastname': '',
    'street': '',
    'number': '',
    'postBus': '',
    'city': '',
    'zip': '',
    'country': '',
    'mail': '',
    'tel': '',
    'btw': '',
}

// these are considered default entries
export const contactDefaultEntries = {
    'name': '',
    'lastname': '',
    'street': '',
    'number': -1,
    'postBus': '',
    'city': '',
    'zip': -1,
    'country': '',
    'mail': '',
    'tel': -1,
    'btw': '',
    'lat': 0,
    'lng': 0
}

export const accFormDefaultEntries = {
    "nameAccommodation": '',
    "type": "-1",
    "guests": -1,
    "bedrooms": -1,
    "bathrooms": -1,
    "singleBeds": -1,
    "doubleBeds": -1,
    "sofaBeds": -1,
    "numNights": -1,
    "checkIn": -1,
    "checkOut": -1,
    "lowerPrice": -1,
    "higherPrice": -1,
}

export const editorDefaultEntries = {
    'paragraph': 'default text',
}

export const enEditorDefaultDisplay = {
    'paragraph': '← click here or double click to add text',
}

export const viewerDefaultDisplay = {
    'paragraph': ""
}

//  ================= NL default text ================== //
//  this is displayed to the user if the database contains default entries (as defined below in 'enContactDefaultEntries')

export const accommodationFormDefaultDisplay = {
    "nameAccommodation": "",
    "type": "Please choose the accommodation type",
    "guests": "",
    "bedrooms": "",
    "singleBeds": 0,
    "doubleBeds": 0,
    "sofaBeds": 0,
    "bathrooms": 0,
    "numNights": 0,
    "checkIn": "00:00",
    "checkOut": "00:00",
    "lowerPrice": 0,
    "higherPrice": 0,
}
