import axios from 'axios';

const SERVER_PORT = process.env.REACT_APP_SERVER_PORT
const BASE_URL = process.env.NODE_ENV === "development" ? `http://localhost:${SERVER_PORT}/api/resirent` : "https://resirent-api-server-a34a467884e2.herokuapp.com/api/resirent"
//const BASE_URL = "https://resirent-api-server-a34a467884e2.herokuapp.com/api/resirent"
//const BASE_URL = "http://localhost:4242/api/resirent"
//const BASE_URL = "http://localhost:3000/api/resirent"
//const BASE_URL = "http://localhost:4242/api/resirent"

export default axios.create({
    baseURL: BASE_URL
})

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});
