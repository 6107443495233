import React from 'react'
import './nav.css'
import {
    NavLink,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { BsPencil } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';


export default function NavItems({ isAdmin, calendarUrl }) {

    const { t } = useTranslation();

    return (
        !isAdmin
            ?
            <div>
                {t("nav_links", { returnObjects: true }).map((item) => (
                    <li key={item} >
                        <NavLink className="nav-link" to={`${item}`}>
                            {t(item)}
                        </NavLink>
                    </li>
                ))
                }
                {calendarUrl !== '' ? (
                    <li>
                        {/* Check whether the calendarUrl is not 'no' */}
                        {/* If it is 'no', then the calendar is not available */}
                        <a href={calendarUrl} className="nav-link">
                            <FontAwesomeIcon icon={faArrowRightFromBracket} /> {t("button.availability")}
                        </a>
                    </li>
                ) : (
                    <></>
                )}

            </div>
            :
            <div>
                {t("nav_links_admin", { returnObjects: true }).map((item) => (
                    <li key={item}>
                        <NavLink className="nav-link" to={`edit/${item}`}>
                            <span style={{ color: "white", paddingRight: "5px" }}>
                                <BsPencil size="15px" />
                            </span>
                            {t(item)}
                        </NavLink>
                    </li>))}
            </div>
    )
}