import logo from '../../assets/Short_logo_dark@4x.png'
import { useEffect, useState, useRef } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useInput from '../../hooks/useInput';
import useToggle from '../../hooks/useToggle';

const LOGIN_URL = '/websiteAuth';

export default function LoginForm() {

    const { setAuth, username } = useAuth();
    const { t } = useTranslation();
    const resiWeb = username;

    const navigate = useNavigate();
    // const location = useLocation();
    // const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    // const errRef = useRef();

    // const [user, setUser] = useState('');
    const [user, resetUser, userAttribs] = useInput('user', '');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [check] = useToggle('persist', true);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ user, pwd, resiWeb }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            const accessToken = response?.data?.accessToken;
            const role = response?.data?.role;
            setAuth({ user, pwd, role, resiWeb, accessToken });
            // setUser('');
            resetUser();
            setPwd('');
            navigate('/edit/dashboard', { replace: true }); //TODO: navigate where??
            // navigate(from, {replace: true});

        } catch (err) {
            if (!err?.response?.data) {
                setErrMsg("The server is not responding, please try again later.");
            } else if (err.response?.data?.message) {
                setErrMsg(err.response.data.message)
            } else {
                setErrMsg("Login failed. Unknown error.")
            }
            // errRef.current.focus();
            // console.error(err);
        }
    }

    // const togglePersist = () => {
    //   setPersist(prev => !prev);
    // }

    // useEffect(() => {
    //   localStorage.setItem("persist", persist);
    // }, [persist])

    return (
        <>
            {/*
        This example requires updating your template:

        ```
        <html className="h-full bg-gray-50">
        <body className="h-full">
        ```
      */}

            {
                <div style={{ display: "flex", minHeight: "100vh", alignItems: "center", justifyContent: "center", padding: "24px 16px", background: "white" }}>
                    <div style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "8px", width: "100%", maxWidth: "md", marginTop: "32px", padding: "24px" }}>
                        <div>
                            <img style={{ display: "block", marginLeft: "auto", marginRight: "auto", height: "48px", width: "auto" }} href="/" src={logo} alt="ResiRent" />
                            <h2 style={{ textAlign: "center", marginTop: "24px", fontSize: "24px", fontWeight: "bold", color: "#1F2937" }}>{t("login.title")}</h2>
                        </div>
                        <form onSubmit={handleSubmit} style={{ marginTop: "24px" }} action="#" method="POST">
                            <input type="hidden" name="remember" defaultValue="true" />
                            <div style={{ marginTop: "16px" }}>
                                <label htmlFor="email-address" style={{ display: "none" }}>{t("login.labelmail")}</label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    ref={userRef}
                                    autoComplete="email"
                                    required
                                    style={{ marginBottom: "16px", display: "block", width: "100%", borderRadius: "4px", border: "1px solid #E5E7EB", padding: "12px", fontSize: "16px", lineHeight: "24px" }}
                                    placeholder={t("login.labelmail")}
                                    {...userAttribs}
                                />
                            </div>
                            <div style={{ marginTop: "16px" }}>
                                <label htmlFor="password" style={{ display: "none" }}>{t("login.labelpass")}</label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    style={{ marginBottom: "16px", display: "block", width: "100%", borderRadius: "4px", border: "1px solid #E5E7EB", padding: "12px", fontSize: "16px", lineHeight: "24px" }}
                                    placeholder={t("login.labelpass")}
                                    value={pwd}
                                    onChange={(e) => setPwd(e.target.value)}
                                />
                            </div>
                            <div style={{ marginTop: "24px" }}>
                                <button
                                    type="submit"
                                    style={{
                                        width: "100%",
                                        padding: "12px",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        borderRadius: "4px",
                                        border: "none",
                                        backgroundColor: "#E4C577",
                                        color: "#FFFFFF",
                                        cursor: check ? "pointer" : "default",
                                    }}
                                >
                                    {t("login.login")}
                                </button>
                            </div>
                        </form>
                        {errMsg && <div className='hint-msg'>{errMsg}</div>}
                    </div>
                </div>
            }
        </>
    )
}


// import logo from '../../assets/Short_logo_dark@4x.png'
// import { useEffect, useState, useRef } from 'react';
// import axios from '../../api/axios';
// import useAuth from '../../hooks/useAuth';
// import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
// import useInput from '../../hooks/useInput';
// import useToggle from '../../hooks/useToggle';

// const LOGIN_URL = '/websiteAuth';

// export default function LoginForm() {

//     const { setAuth, username } = useAuth();
//     const { t } = useTranslation();
//     const resiWeb = username;

//     const navigate = useNavigate();
//     // const location = useLocation();
//     // const from = location.state?.from?.pathname || "/";

//     const userRef = useRef();
//     // const errRef = useRef();

//     // const [user, setUser] = useState('');
//     const [user, resetUser, userAttribs] = useInput('user', '');
//     const [pwd, setPwd] = useState('');
//     const [errMsg, setErrMsg] = useState('');
//     const [check] = useToggle('persist', true);

//     useEffect(() => {
//         userRef.current.focus();
//     }, [])

//     useEffect(() => {
//         setErrMsg('');
//     }, [user, pwd])

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await axios.post(LOGIN_URL,
//                 JSON.stringify({ user, pwd, resiWeb }),
//                 {
//                     headers: { 'Content-Type': 'application/json' },
//                     withCredentials: true
//                 }
//             );
//             const accessToken = response?.data?.accessToken;
//             const role = response?.data?.role;
//             setAuth({ user, pwd, role, resiWeb, accessToken });
//             // setUser('');
//             resetUser();
//             setPwd('');
//             navigate('/edit/dashboard', { replace: true }); //TODO: navigate where??
//             // navigate(from, {replace: true});

//         } catch (err) {
//             if (!err?.response?.data) {
//                 setErrMsg("The server is not responding, please try again later.");
//             } else if (err.response?.data?.message) {
//                 setErrMsg(err.response.data.message)
//             } else {
//                 setErrMsg("Login failed. Unknown error.")
//             }
//             // errRef.current.focus();
//             // console.error(err);
//         }
//     }

//     // const togglePersist = () => {
//     //   setPersist(prev => !prev);
//     // }

//     // useEffect(() => {
//     //   localStorage.setItem("persist", persist);
//     // }, [persist])

//     return (
//         <>
//             {/*
//         This example requires updating your template:

//         ```
//         <html className="h-full bg-gray-50">
//         <body className="h-full">
//         ```
//       */}

//             {
//                 <div style={{ display: "flex", minHeight: "100vh", alignItems: "center", justifyContent: "center", padding: "24px 16px", background: "white" }}>
//                     <div style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "8px", width: "100%", maxWidth: "md", marginTop: "32px", padding: "24px" }}>
//                         <div>
//                             <img style={{ display: "block", marginLeft: "auto", marginRight: "auto", height: "48px", width: "auto" }} href="/" src={logo} alt="ResiRent" />
//                             <h2 style={{ textAlign: "center", marginTop: "24px", fontSize: "24px", fontWeight: "bold", color: "#1F2937" }}>{t("login.title")}</h2>
//                         </div>
//                         <form onSubmit={handleSubmit} style={{ marginTop: "24px" }} action="#" method="POST">
//                             <input type="hidden" name="remember" defaultValue="true" />
//                             <div style={{ marginTop: "16px" }}>
//                                 <label htmlFor="email-address" style={{ display: "none" }}>{t("login.labelmail")}</label>
//                                 <input
//                                     id="email-address"
//                                     name="email"
//                                     type="email"
//                                     ref={userRef}
//                                     autoComplete="email"
//                                     required
//                                     style={{ marginBottom: "16px", display: "block", width: "100%", borderRadius: "4px", border: "1px solid #E5E7EB", padding: "12px", fontSize: "16px", lineHeight: "24px" }}
//                                     placeholder={t("login.labelmail")}
//                                     {...userAttribs}
//                                 />
//                             </div>
//                             <div style={{ marginTop: "16px" }}>
//                                 <label htmlFor="password" style={{ display: "none" }}>{t("login.labelpass")}</label>
//                                 <input
//                                     id="password"
//                                     name="password"
//                                     type="password"
//                                     autoComplete="current-password"
//                                     required
//                                     style={{ marginBottom: "16px", display: "block", width: "100%", borderRadius: "4px", border: "1px solid #E5E7EB", padding: "12px", fontSize: "16px", lineHeight: "24px" }}
//                                     placeholder={t("login.labelpass")}
//                                     value={pwd}
//                                     onChange={(e) => setPwd(e.target.value)}
//                                 />
//                             </div>
//                             <div style={{ marginTop: "24px" }}>
//                                 <button
//                                     type="submit"
//                                     style={{
//                                         width: "100%",
//                                         padding: "12px",
//                                         fontSize: "16px",
//                                         fontWeight: "bold",
//                                         borderRadius: "4px",
//                                         border: "none",
//                                         backgroundColor: "#E4C577",
//                                         color: "#FFFFFF",
//                                         cursor: check ? "pointer" : "default",
//                                     }}
//                                 >
//                                     {t("login.login")}
//                                 </button>
//                             </div>
//                         </form>
//                         {errMsg && <div className='hint-msg'>{errMsg}</div>}
//                     </div>
//                 </div>
//             }
//         </>
//     )
// }
