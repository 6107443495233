import React, { useState, useEffect } from 'react'
import './nav.css'
import useAuth from '../../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import axios from '../../api/axios';
import useIsMobile from '../../hooks/useIsMobile';
import NavItems from './NavItems';
import NavItemsMobile from './NavItemsMobile';

const LANG_URL = '/contact';
const WEBSITE_URL = '/websiteAuth/calendar';

export default function Nav() {
  //hooks
  const { i18n } = useTranslation();
  const { auth, username } = useAuth();
  const [languages, setLanguages] = useState([]);
  const isMobile = useIsMobile();
  const [calendar, setCalendar] = useState([]);

  useEffect(() => {
    const getContent = async () => {
      await axios.get(`${WEBSITE_URL}`, {
        params: { username },
      }).then((res) => {
        setCalendar(res?.data?.calendar);
      }).catch((err) => {
        console.log(err);
      });
      axios.get(`${LANG_URL}`, {
        params: { username, art: "nl", title: "contact" },
      }).then((res) => {
        setLanguages(res?.data?.entry?.languages?.split(","));
      }).catch((err) => {
        console.log(err);
      });
    };
    getContent();
  }, [username]);

  return (
    <div>
      <ul>
        <div className='navbar'>
          {
            isMobile ?
              <NavItemsMobile isAdmin={auth.role} calendarUrl={calendar} />
              :
              <NavItems isAdmin={auth.role} calendarUrl={calendar} />
          }
          <li className="dropdown">
            <div className='nav-link' style={{ color: "white" }}>
              {
                !isMobile && <div style={{ display: "inline-flex" }}>
                  {i18n.language}
                  <span className="hspace"></span>
                </div>
              }
              <FontAwesomeIcon
                icon={faGlobe}
                className="absolute top-1/2 right-0 transform -translate-y-1/2 pointer-events-none text-white"
              />
            </div>
            {/* change prefix from list */}
            {languages !== null && <div className="dropdown-content">
              {languages?.map((code) => (
                <button
                  key={code}
                  value={code}
                  className='drop'
                  onClick={(e) => {
                    i18n.changeLanguage(e.target.value)
                  }}>
                  {code}
                </button>
              ))}
            </div>}
          </li>
        </div>
      </ul>
    </div>
  )
}