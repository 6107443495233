import { useTranslation } from "react-i18next";
import { ExternalLinkButton } from "../buttons/Buttons";
import ImageContent from "../dynamic/ImageContent";
import useAuth from "../../hooks/useAuth";

const Suggestion = ({ suggestion, filterTags, setFilterTags }) => {
    const { t } = useTranslation();
    const { username } = useAuth();

    // return true if the intersection of set filters and tags on the suggestion is not empty, 
    // or if no filters are set
    function hasFilteredTag(filteredTags, suggTags) {
        if (filteredTags?.length === 0) {
            return true
        }
        var filteredArray = filteredTags.filter(function (n) {
            return suggTags.indexOf(n) !== -1;
        });
        return filteredArray?.length !== 0
    }

    const handleTagToggle = (tag) => {

        if (filterTags.includes(tag)) {
            setFilterTags(filterTags.filter(item => item !== tag))
        } else {
            setFilterTags(filterTags => [...filterTags, tag])
        }

    }

    return (
        <div className="suggestion-container">
            {(hasFilteredTag(filterTags, suggestion?.tags) ? (
                <div className="suggestion" key={suggestion.id}>
                    <ImageContent
                        username={username}
                        title={"suggestions_" + suggestion?.id}
                        type="image-suggestions"
                        smallEditButton={true}
                        showEditButton={false}
                        showPlaceholder={false}
                    />
                    <h3>{suggestion?.title}</h3>
                    <p >{suggestion?.intro}</p>
                    <p>{suggestion?.address}</p>
                    <p >{suggestion?.tel}</p>
                    <a href={suggestion?.weblink} target="_blank" rel="noreferrer">
                        {suggestion?.weblink && <ExternalLinkButton buttonText={t("suggestions_page.website-tag")} />}
                    </a>
                    <div className="tags-container">
                        {suggestion?.tags?.map((tag) => {
                            return (
                                <button
                                    className={filterTags.includes(tag) ? "tag-selected" : "tag"}
                                    onClick={() => { handleTagToggle(tag) }}
                                    key={tag}>
                                    {tag}
                                </button>)
                        })}
                    </div>
                </div>
            ) : <div></div>)
            }
        </div>
    );
};

export default Suggestion;