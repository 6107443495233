export default function StylePreview(props) {

    return (
        <div style={{ marginBottom: "10px" }}>
            <h3>{props.title}</h3>
            <div className="pw-bg" style={{ backgroundColor: props.background }}>
                <div className="pw-nav" style={{ backgroundColor: props.navigation }}>
                </div>
                <div style={{ marginTop: "7px", marginRight: "5px", marginLeft: "0px" }}>
                    <h3 style={{ fontFamily: props.heading }}>
                        Lorem Ipsum
                    </h3>
                </div>
                <div className="pw-accent" style={{ backgroundColor: props.accent }}>
                    <div className="pw-button" style={{ backgroundColor: props.button }}></div>
                    <div style={{ marginRight: "5px", marginLeft: "70px" }}>
                        <p style={{ paddingTop: "5px", fontFamily: props.paragraph }}>
                            {props.sample1}
                        </p>
                    </div>
                </div>
                <div className="pw-button" style={{ backgroundColor: props.button }}></div>
                <div style={{ marginTop: "7px", marginRight: "5px", marginLeft: "70px" }}>
                    <p style={{ fontFamily: props.paragraph }}>
                        {props.sample2}
                    </p>
                </div>

            </div>
        </div>
    )
}