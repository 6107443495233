import React, { useRef, useCallback, useState, useEffect } from 'react';
import axios from '../../api/axios';
import ReviewsCarousel from './Review';
import { BsPencil } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { defaultLanguage } from '../../constants';
import { AddButton, CancelButton, DeleteButton, EditButtonLarge, SaveButton } from '../buttons/Buttons';

const WEBSITE_URL = '/websitejson';

export default function ReviewsjsonEdit() {
    const art = defaultLanguage;
    const title = "reviews-frontpage";
    const { username } = useAuth();
    const { t } = useTranslation();

    // States
    const [editing, setEditing] = useState(false);
    const [editingReviewId, setEditingReviewId] = useState(1);

    // JSON input
    const [contentTitle, setContentTitle] = useState('');
    const [comment, setComment] = useState('');
    const [guestName, setGuestName] = useState('');
    const [date, setDate] = useState('');

    // Default example
    const [reviews, setReviews] = useState([
        {
            id: -1,
            title: 'Review Title',
            comment: 'Review Text',
            guestName: 'Guest Name',
            date: 'date'
        },
    ]);

    const reviewsRef = useRef(reviews);

    useEffect(() => {
        reviewsRef.current = reviews;
    }, [reviews]);

    const updateReviews = useCallback(async () => {
        try {
            const response = await axios.get(`${WEBSITE_URL}`, {
                params: { username, art, title },
            });
            if (response.data.entry) {
                const reviewsData = JSON.parse(response.data.entry.content);
                let transformedReviews;
                if ((reviewsData !== "default text") && reviewsData) {
                    transformedReviews = reviewsData.map((review) => ({
                        id: review.id,
                        title: review.title,
                        comment: review.comment,
                        guestName: review.guestName,
                        date: review.date,
                    }));
                } else {
                    transformedReviews = reviewsRef.current;
                }
                setReviews(transformedReviews);
            }
        } catch (err) {
            console.log(err);
        }
    }, [username, art, title]);

    useEffect(() => {
        const update = async () => {
            await updateReviews();
        }
        update();
    }, [updateReviews]);



    const handleEditClick = () => {
        setEditing(true);
    };

    const handleDeleteClick = async (id) => {
        if (reviews) {
            try {
                setReviews(reviews.filter((review) => review !== id));
                const reviewSmall = reviews.filter((review) => review !== id);
                await axios.put(WEBSITE_URL, {
                    username,
                    art,
                    title,
                    content: reviewSmall,
                });
            } catch (err) {
                console.log(err);
            }
        }
    };

    const handleAddClick = async (event) => {
        event.preventDefault();
        const newReview = {
            id: Date.now(), // use the current timestamp as the unique id
            title: contentTitle,
            comment: comment,
            guestName: guestName,
            date: date,
        };
        setReviews([...reviews, newReview]);
        setComment("");
        setGuestName("");
        setDate("");
        try {
            await axios.put(WEBSITE_URL, {
                username,
                art,
                title,
                content: [...reviews, newReview],
            });
        } catch (err) {
            console.log(err);
        }
    };

    const handleSaveClick = async (event) => {
        event.preventDefault();
        const newReview = {
            id: editingReviewId, // use the current timestamp as the unique id
            title: contentTitle,
            comment: comment,
            guestName: guestName,
            date: date,
        };
        const oldReviewIndex = reviews.findIndex(review => review.id === editingReviewId);
        const updatedReviews = [...reviews];
        if (oldReviewIndex !== -1) {
            updatedReviews.splice(oldReviewIndex, 1, newReview);
        } else {
            updatedReviews.push(newReview);
        }
        setReviews(updatedReviews);
        setContentTitle("");
        setComment("");
        setGuestName("");
        setDate("");
        setEditingReviewId(1);
        try {
            await axios.put(WEBSITE_URL, {
                username,
                art,
                title,
                content: updatedReviews,
            });
        } catch (err) {
            console.log(err);
        }
        updateReviews();
    };

    const handleCancelClick = () => {
        updateReviews();
        setEditing(false);
    };

    const handleEditClickReview = (id) => {
        console.log(id)
        setEditingReviewId(id.id);
        setComment(id.comment);
        setGuestName(id.guestName);
        setDate(id.date);
        setContentTitle(id.title);
        editReview(id);
    };

    const handleInnerCancelClick = () => {
        setComment("");
        setGuestName("");
        setDate("");
        setContentTitle("");
        setEditingReviewId(1);
    }

    const editReview = (item) => {
        return (
            <div className="container">
                <form className="review">
                    <div className="form-row">
                        <label htmlFor="contentTitle">Title:</label>
                        <input
                            type="text"
                            id="title"
                            placeholder={item.title}
                            value={contentTitle}
                            className="review"
                            onChange={(event) => setContentTitle(event.target.value)}
                        />
                    </div>
                    <div className="form-row">
                        <label htmlFor="comment">Review:</label>
                        <textarea
                            id="comment"
                            value={comment}
                            placeholder={item.comment}
                            className="review"
                            style={{ minHeight: "120px" }}
                            onChange={(event) => setComment(event.target.value)}
                        ></textarea>
                    </div>
                    <div className="form-row">
                        <label htmlFor="guestName">Name:</label>
                        <input
                            type="text"
                            id="guestName"
                            value={guestName}
                            placeholder={item.guestName}
                            className="review half-width"
                            onChange={(event) => setGuestName(event.target.value)}
                        />
                        <label
                            className='margin-10px'
                            htmlFor="guestName">Date:</label>
                        <input
                            type="text"
                            id="guestName"
                            value={date}
                            placeholder={item.date}
                            className="review half-width"
                            onChange={(event) => setDate(event.target.value)}
                        />
                    </div>
                    <SaveButton
                        buttonText={t("button.save")}
                        onClick={(e) => handleSaveClick(e)}
                        type='submit'
                    />
                    <CancelButton
                        buttonText={t("button.cancel")}
                        onClick={handleInnerCancelClick}
                    />
                </form>
            </div>
        )
    }

    return (
        <>
            <h2
                style={{ marginBottom: "30px" }}>
                {editing ? (
                    <span className='edit-text' onClick={handleCancelClick}>
                        <MdCancel size="25px" />
                    </span>
                ) : (
                    <span onClick={handleEditClick} className='edit-text'>
                        <BsPencil size="25px" />
                    </span>
                )}
                {t("home_page.reviews")}
            </h2>
            {
                editing ?
                    (<div>
                        {reviews && reviews.map((review) => (
                            <div key={review.id}>
                                {editingReviewId === review.id ? (
                                    editReview(review)
                                ) : (
                                    <div className="review review-bg">
                                        <h3>{review.title}</h3>
                                        <p className="review-txt">{review.comment}</p>
                                        <p className="review-info">
                                            {t("reviews.reviewed")} <span className="guest-name">{review.guestName}</span> {t("reviews.on")}{" "}
                                            <span className="review-date">{review.date}</span>
                                        </p>
                                    </div>
                                )}
                                <div className="review-actions">
                                    {(editingReviewId === review.id) ? (
                                        <></>
                                    ) : (
                                        <>
                                            <EditButtonLarge
                                                buttonText={t("button.edit")}
                                                onClick={() => handleEditClickReview(review)}
                                            />
                                            <DeleteButton
                                                buttonText={t("button.delete")}
                                                onClick={() => handleDeleteClick(review)}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        ))
                        }
                        {editingReviewId === 1 ? (
                            <div className="container">
                                <form className="review">
                                    <div className="form-row">
                                        <label htmlFor="contentTitle">Title:</label>
                                        <input
                                            type="text"
                                            id="title"
                                            value={contentTitle}
                                            className="review"
                                            onChange={(event) => setContentTitle(event.target.value)}
                                        />
                                    </div>
                                    <div className="form-row">
                                        <label htmlFor="comment">Review:</label>
                                        <textarea
                                            id="comment"
                                            value={comment}
                                            className="review"
                                            onChange={(event) => setComment(event.target.value)}
                                        ></textarea>
                                    </div>
                                    <div className="form-row">
                                        <label htmlFor="guestName">Name:</label>
                                        <input
                                            type="text"
                                            id="guestName"
                                            value={guestName}
                                            className="review half-width"
                                            onChange={(event) => setGuestName(event.target.value)}
                                        />
                                        <label
                                            className='margin-10px'
                                            htmlFor="guestName">Date:</label>
                                        <input
                                            type="text"
                                            id="guestName"
                                            value={date}
                                            className="review half-width"
                                            onChange={(event) => setDate(event.target.value)}
                                        />
                                    </div>
                                    <AddButton
                                        buttonText={t("button.addReview")}
                                        onClick={(e) => handleAddClick(e)}
                                        type="submit"
                                    />
                                </form>
                            </div>) : (
                            <></>
                        )
                        }
                    </div>)
                    : (
                        <div>
                            {reviews && <ReviewsCarousel reviews={reviews} />}
                        </div>
                    )
            }
        </>
    )
};
