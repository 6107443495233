import React, { useRef, useCallback, useState, useEffect } from 'react';
import axios from '../../api/axios';
import ReviewsCarousel from './Review';
import { defaultLanguage } from '../../constants';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

const WEBSITE_URL = '/websitejson';

export default function Contentjson() {
    const art = defaultLanguage;
    const title = "reviews-frontpage";

    const [empty] = useState(false);
    const { username } = useAuth();
    const { t } = useTranslation();

    const [reviews, setReviews] = useState([
        {
            id: 1,
            title: 'Great place to stay!',
            comment: 'The accommodations were clean and comfortable. The staff was friendly and helpful.',
            guestName: 'John Smith',
            date: 'March 20, 2022'
        },
    ]);

    const reviewsRef = useRef(reviews);

    useEffect(() => {
        reviewsRef.current = reviews;
    }, [reviews]);

    const updateReviews = useCallback(async () => {
        try {
            const response = await axios.get(`${WEBSITE_URL}`, {
                params: { username, art, title },
            });
            if (response.data.entry) {
                const reviewsData = JSON.parse(response.data.entry.content);
                let transformedReviews;
                if (reviewsData !== "default text") {
                    transformedReviews = reviewsData.map((review) => ({
                        id: review.id,
                        title: review.title,
                        comment: review.comment,
                        guestName: review.guestName,
                        date: review.date,
                    }));
                } else {
                    transformedReviews = reviewsRef.current;
                }
                setReviews(transformedReviews);
            }
        } catch (err) {
            console.log(err);
        }
    }, [username, art, title]);

    useEffect(() => {
        const update = async () => {
            await updateReviews();
        }
        update();
    }, [updateReviews]);


    return (
        <>
            <h2 style={{ marginBottom: "30px" }}>{t("home_page.reviews")} </h2>

            {
                (!empty) ? <ReviewsCarousel reviews={reviews} /> : <p className="edit-sign" style={{ textAlign: 'center' }}>Coming soon!</p>

            }
        </>);
}
