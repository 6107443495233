export const defaultColors = [
    {
        name: "Skyline",
        background: "#FFFFFF",
        navigation: "#152A35",
        accent: "#E5E3D7",
        button: "#9B8D58"
    },
    {
        name: "Foil-covered Sneakers",
        background: "#FBF8EF",
        navigation: "#1F2132",
        accent: "#EDDA92",
        button: "#B5D6F2"
    },
    {
        name: "Lake View",
        background: "#FAFCFF",
        navigation: "#2C020A",
        accent: "#C3D0E9",
        button: "#DB994D"
    },
    {
        name: "Milky Stream",
        background: "#FFFFFF",
        navigation: "#13292A",
        accent: "#E7DED9",
        button: "#988780"
    },
    {
        name: "Berry Jar",
        background: "#FFFAFA",
        navigation: "#2B2124",
        accent: "#E4AFB2",
        button: "#139694"
    },
    {
        name: "Makeshift Slumber",
        background: "#F9FAFA",
        navigation: "#191A1A",
        accent: "#E5E2DC",
        button: "#8A9084"
    },
    {
        name: "Wall Greens",
        background: "#FEFFFA",
        navigation: "#21231F",
        accent: "#D8E1B7",
        button: "#747E6D"
    },
    {
        name: "Sweet Frost Lips",
        background: "#FFF8F5",
        navigation: "#301214",
        accent: "#F0DBC7",
        button: "#966754"
    },
    {
        name: "Deep Merlot",
        background: "#FFFCFA",
        navigation: "#170206",
        accent: "#E1CBD1",
        button: "#879FB0"
    },
]