import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from '../../api/axios';
import { defaultLanguage } from '../../constants';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { CancelButton, EditButtonLarge, SaveButton } from '../buttons/Buttons';

const WEBSITE_URL = '/websitejson';

const ReviewLinks = () => {
    const { username, auth } = useAuth();
    const { t } = useTranslation();

    const art = defaultLanguage;
    const title = "review-links2";

    // States
    const [editingReviewId, setEditingReviewId] = useState(0);

    // JSON input
    const [link, setLink] = useState('');
    const [toShow, setToShow] = useState(0);
    const [heading, setHeading] = useState('');

    // Default entry
    const [reviews, setReviews] = useState([
        {
            id: 1,
            link: '',
            toShow: 1,
            heading: 'Google',
        },
        {
            id: 2,
            link: '',
            toShow: 1,
            heading: 'AirBnB',
        },
        {
            id: 3,
            link: '',
            toShow: 1,
            heading: 'Booking',
        },
        {
            id: 4,
            link: '',
            toShow: 1,
            heading: 'Others',
        },
    ]);

    const reviewsRef = useRef(reviews);

    useEffect(() => {
        reviewsRef.current = reviews;
    }, [reviews]);

    const updateReviews = useCallback(async () => {
        try {
            const response = await axios.get(`${WEBSITE_URL}`, {
                params: { username, art, title },
            });
            if (response.data.entry) {
                const reviewsData = JSON.parse(response.data.entry.content);
                let transformedReviews;
                if (reviewsData !== "default text") {
                    transformedReviews = reviewsData.map((review) => ({
                        id: review.id,
                        link: review.link,
                        toShow: review.toShow,
                        heading: review.heading,
                    }));
                } else {
                    transformedReviews = reviewsRef.current;
                }
                setReviews(transformedReviews);
            }
        } catch (err) {
            console.log(err);
        }
    }, [username, art, title])

    useEffect(() => {
        const update = async () => {
            await updateReviews();
        }
        update();
    }, [updateReviews]);

    const handleEditClickReview = (id) => {
        setEditingReviewId(id.id);
        setLink(id.link);
        setToShow(id.toShow);
        setHeading(id.heading);
    };

    const handleSaveClick = async (event) => {
        event.preventDefault();
        const newReview = {
            id: editingReviewId, // use the current timestamp as the unique id
            toShow: toShow,
            link: link,
            heading: heading,
        };
        const oldReviewIndex = reviews.findIndex(review => review.id === editingReviewId);
        const updatedReviews = [...reviews];
        if (oldReviewIndex !== -1) {
            updatedReviews.splice(oldReviewIndex, 1, newReview);
        } else {
            updatedReviews.push(newReview);
        }
        setReviews(updatedReviews);
        setToShow(1);
        setLink("");
        setEditingReviewId(0);
        try {
            await axios.put(WEBSITE_URL, {
                username,
                art,
                title,
                content: updatedReviews,
            });
        } catch (err) {
            console.log(err);
        }
        updateReviews();
    };

    const handleCancelClick = () => {
        setToShow(1);
        setLink("");
        setEditingReviewId(0);
    }


    const editReview = (item) => {
        return (
            <div className='container' style={{ width: '100%' }}>
                <form className="review">
                    <div style={{ textAlign: 'center' }}>
                        <h3>{item.heading}</h3>
                        <label className="form-label" htmlFor="toShow" style={{ display: "flex", alignItems: "center" }}>
                            {t("reviews.available")}
                            <input
                                type="checkbox"
                                id="toShow"
                                value={toShow}
                                checked={toShow}
                                style={{ marginLeft: "10px" }}
                                onChange={(event) => setToShow(event.target.checked)}
                            />
                        </label>
                        <div>
                            <label htmlFor="category">{t("reviews.add_link")}</label>
                            <input
                                type="text"
                                id="link"
                                value={link}
                                style={{ width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc", backgroundColor: "#f5f5f5", color: "#333" }}
                                onChange={(event) => setLink(event.target.value)}
                            />
                        </div>
                        <br />
                    </div>
                    <SaveButton
                        buttonText={t("button.save")}
                        onClick={(e) => handleSaveClick(e)}
                        type='submit'
                    />
                    <CancelButton
                        buttonText={t("button.cancel")}
                        onClick={handleCancelClick}
                    />
                </form>
            </div>
        )
    }

    return (
        <div>
            <h2 style={{ marginBottom: "30px" }}>
                {t("reviews.title_add_link")}
            </h2>
            {(auth?.role ?
                (
                    <div className="container p review-links" style={{ gap: editingReviewId === 0 ? '80px' : '30px' }}>
                        {reviews.map((review) => (
                            <div key={review.id}>
                                {editingReviewId === review.id ? (
                                    editReview(review)
                                ) : (
                                    <div style={{ textAlign: 'center' }}>
                                        <div key={review.id} style={{ width: '150px' }}>
                                            <a href={review.link} className="black-link">
                                                <h3>{review.heading}</h3>
                                            </a>
                                        </div>
                                        <EditButtonLarge
                                            buttonText={t("button.edit")}
                                            onClick={() => handleEditClickReview(review)}
                                        />
                                    </div>
                                )}
                            </div>

                        ))}
                    </div>
                ) : (
                    <div className='review-links'>
                        {reviews.map((review) => (
                            <div key={review.id}>
                                <div style={{ textAlign: 'center', padding: "20px" }}>
                                    {review.toShow && (
                                        <div key={review.id}>
                                            <a href={review.link} className="black-link" style={{ display: "inline-flex" }} >                                                <h3>{review.heading}</h3>
                                            </a>                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                ))
            }
        </div>
    );
};

export default ReviewLinks;



