import React, { lazy, Suspense } from "react";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import ImageContent from "../dynamic/ImageContent";
import axios from '../../api/axios.js';
import { useEffect } from "react";
import { IconArrowRight } from "@tabler/icons-react";
import Content from "../dynamic/Content.jsx";
import FetchContent from "../dynamic/fetchContent.jsx";
const FetchImage = lazy(() => import('../dynamic/fetchImage'))
const WEBSITE_URL = '/dashboard/key';

const TourismOffice = () => {
    const [isSmallScreen, setIsSmallScreen] = React.useState(false);
    const { username, auth } = useAuth();
    const { t } = useTranslation();
    const [tourismOfficeLink, setTourismOfficeLink] = React.useState("");
    const buttonStyle = {
        padding: '10px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#4C7785',
        border: 'none',
        borderRadius: '5px',
        textDecoration: 'none',
        display: 'inline-block',
        textAlign: 'center',
        cursor: 'pointer',
        opacity: '80%',
    };

    React.useLayoutEffect(() => {
        function updateScreenSize() {
            setIsSmallScreen(window.innerWidth < 768);
        }
        window.addEventListener('resize', updateScreenSize);
        updateScreenSize();
        return () => window.removeEventListener('resize', updateScreenSize);
    }, []);

    useEffect(() => {
        const fetchContent = async () => {
            try {
                await axios.get(WEBSITE_URL, {
                    params: {
                        username: username,
                        key: 'tourismOfficeLink', // specify the column you want to retrieve
                    },
                }).then((res) => {
                    setTourismOfficeLink(res?.data?.tourismOfficeLink);
                });
            } catch (error) {
                console.error(error);
            }
        };
        fetchContent();
    }, [username]);

    return (
        <>
            {isSmallScreen ? (
                <div className="p dog-friendly-home">
                    <div className="image-container">
                        <Suspense fallback={<h1>Still Loading…</h1>}>
                            {(!auth?.role ?
                                <FetchImage title="tourismOffice" username={username} type="image-features" />
                                :
                                <ImageContent title="tourismOffice" username={username} type="image-features" />
                            )}
                        </Suspense>
                    </div>
                    {/* <h3 className="edit-sign features">{t("suggestions_page.tourism")}</h3> */}
                    <div style={{ textAlign: 'center', width: "65%" }}>
                        {(tourismOfficeLink ?
                            <a href={tourismOfficeLink} target="_blank" rel="noreferrer" style={buttonStyle}>{t("dashboard_page.acc_form.tourismOfficeLink")} <IconArrowRight size="25px" /> </a>
                            :
                            <></>
                        )}
                        {(!auth?.role ?
                            <div className="p">
                                <FetchContent title="p-tourismOffice" />
                            </div> :
                            <div className="p">
                                <Content title={"p-tourismOffice"} styling="p" />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="p dog-friendly-home">
                    <div className="image-container">
                        <Suspense fallback={<h1>Still Loading…</h1>}>
                            {(!auth?.role ?
                                <FetchImage title="tourismOffice" username={username} type="image-features" />
                                :
                                <ImageContent title="tourismOffice" username={username} type="image-features" />
                            )}
                        </Suspense>
                    </div>
                    <div className="container">
                        {/* <h3 className="edit-sign">{t("suggestions_page.tourism")}</h3> */}
                        <div className="edit-sign" style={{ textAlign: 'center' }}>
                            {(tourismOfficeLink ?
                                <a href={tourismOfficeLink} rel="noreferrer" style={buttonStyle}>{t("suggestions_page.tourism-tag")} <IconArrowRight size="25px" /> </a>
                                :
                                <></>
                            )}
                            {(!auth?.role ?
                                <div className="p">
                                    <FetchContent title="p-tourismOffice" />
                                </div> :
                                <div className="p">
                                    <Content title={"p-tourismOffice"} styling="p" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TourismOffice;



