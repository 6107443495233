export const fonts = [
    "Roboto Slab",
    "Lora",
    "Playfair Display",
    "PT Serif",
    "Noto Serif",
    "Libre Baskerville",
    "Bitter",
    "Abril Fatface",
    "Cormorant",
    "Old Standard TT",
    "Cardo",
    "Roboto",
    "Open Sans",
    "Lato",
    "Merriweather",
    "Montserrat",
    "Noto Sans",
    "Crimson Text",
].sort((a, b) => a.localeCompare(b))

export const importFonts = [
    fonts.map((font) => {
        return font.replace(" ", "+") + "|"
    })
]