import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import cloudinaryConfig from '../../config/cloudinary.config';
import emptyImage from '../../assets/empty.png'
import imageCompression from 'browser-image-compression';
import { useTranslation } from 'react-i18next';
import { CancelButton, EditImageButton } from '../buttons/Buttons';

const WEBSITE_URL = '/image_url';

export default function ImageContent({ username, title, type, smallEditButton = false, showEditButton = true, showPlaceholder = true }) {

  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState(null);
  const [currentImageURL, setCurrentImageURL] = useState(null);
  const [newImageURL, setNewImageURL] = useState(null);
  const [editing, setEditing] = useState(false);
  const [publicID, setPublicID] = useState("");

  // load on render
  useEffect(() => {
    const getContent = async () => {
      await axios.get(`${WEBSITE_URL}`, {
        params: { username, title },
      }).then((res) => {
        setCurrentImageURL(res?.data);

      }).catch(err => {
        // Handle different HTTP error codes here
        if (err.response) {
          if (err.response.status === 404) {
            setCurrentImageURL(emptyImage)
          } else {
            console.error('HTTP error! Status:', err.response.status);
            console.error('Response:', err.response);
          }
        } else if (err.request) {
          console.error('No response received:', err.request);
        } else {
          console.error('Error setting up the request:', err.message);
        }
      });
    };
    getContent().catch((err) => {
      console.log("caught you");
    });;
  }, [title, username]);

  // update image url in database
  useEffect(() => {
    const update = async () => {
      if (newImageURL && username && title) {
        await axios.put('/image_url', {
          username: username,
          title: title,
          public_id: publicID,
          url: newImageURL
        }).catch((err) => {
          console.log(err);
        })
      }
    }
    update();
  }, [newImageURL, title, username, publicID]);

  // upload to cloud on file input change
  useEffect(() => {
    const upload = async () => {
      // get signature for upload
      const signResponse = await axios.get('/signuploadform', {
        params: {
          folder: username,
          publicID: title
        }
      });
      const signData = signResponse.data;
      const url = cloudinaryConfig.BASE_URL + signData.cloudName + cloudinaryConfig.UPLOAD_SUFFIX;
      // populate form for upload
      const formData = new FormData();
      formData.append("api_key", signData.apiKey);
      formData.append("cloud_name", signData.cloudName);
      formData.append("file", selectedFile);
      formData.append("folder", username);
      formData.append("public_id", title);
      formData.append("signature", signData.signature);
      formData.append("timestamp", signData.timestamp);


      // upload to cloudinary
      if (url && signData && selectedFile) {
        await axios.post(url, formData)
          .then((res) => {
            setNewImageURL(res.data.secure_url);
            setCurrentImageURL(res.data.secure_url);
            setPublicID(res.data.public_id);
          })
          .catch((err) => {
            console.log(err);
          });
        setEditing(false);
      }
    }
    upload();
  }, [selectedFile, title, username]);


  // React compress image component

  async function handleFileInputChange(event) {
    const imageFile = event.target.files[0];
    const maxSizeMB = 1;
    const maxWidthOrHeight = 1920;

    // Check if image size is larger than 1MB
    if (imageFile.size > maxSizeMB * 1024 * 1024) {
      const options = {
        maxSizeMB: maxSizeMB,
        maxWidthOrHeight: maxWidthOrHeight,
      };
      await imageCompression(imageFile, options)
        .then((result) => {
          setSelectedFile(result);
        }
        ).catch((err) => {
          console.log(err);
        });
    } else {
      // Image size is smaller than 1MB, use the original file
      setSelectedFile(imageFile);
    }
  }


  return (
    <div className='image-content'>
      <div className={type}>
        {currentImageURL ? (
          <div>
            {!(currentImageURL === emptyImage && !showPlaceholder) && <img className={type} src={currentImageURL} alt="header content" />}
          </div>
        ) : (
          <div>
            {showPlaceholder && <img className={type} src={emptyImage} alt="connection error" />}
          </div>
        )
        }
        {editing ? (
          <CancelButton
            buttonText={t("button.cancel")}
            onClick={() => setEditing(false)}
          />

        ) : (
          <div>
            {showEditButton &&
              <div style={{ position: "absolute", top: "10px", left: "10px" }}>
                <EditImageButton
                  buttonText={t("button.edit_image")}
                  onClick={() => setEditing(true)}
                  small={smallEditButton}
                />
              </div>}
          </div>
        )}
      </div>
      <div>
        {editing && <input className='image-input' type="file" accept='image/jpeg, image/png' onChange={handleFileInputChange} />}
      </div>
    </div>
  );
}
