import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { defaultLanguage } from '../../constants';
import { accFormDefaultEntries, contactDefaultEntries, accommodationFormDefaultDisplay } from '../../languages/defaultEntries';
const WEBSITE_URL = '/dashboard';
const WEBSITE_URL_CONTACT = '/contact/key';

const Practicalities = () => {

    const art = defaultLanguage;
    const title = "accomodationForm";
    const { username, auth } = useAuth();
    const { t } = useTranslation();

    const [bedrooms, setBedrooms] = useState(-1);
    const [bathrooms, setBathrooms] = useState(-1);
    const [dogs, setDogs] = useState(false);
    const [wheelchair, setWheelchair] = useState(false);
    const [parking, setParking] = useState(false);
    const [childrenBed, setChildrenBed] = useState(false);
    const [singleBeds, setSingleBeds] = useState(-1);
    const [doubleBeds, setDoubleBeds] = useState(-1);
    const [sofaBeds, setSofaBeds] = useState(-1);
    const [checkIn, setCheckIn] = useState('00:00:00');
    const [checkOut, setCheckOut] = useState('00:00:00');
    const [wifi, setWifi] = useState(true);
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState(1);
    const [zip, setZip] = useState(1);
    const [lowerPrice, setLowerPrice] = useState(0);
    const [higherPrice, setHigherPrice] = useState(0);
    const [cleaningIncluded, setCleaningIncluded] = useState(false);
    const [cityTaxIncluded, setcityTaxIncluded] = useState(false);
    const [postBus, setPostBus] = useState("");
    const [selfCheckIn, setSelfCheckIn] = useState(false);
    const [numNights, setNumNights] = useState(0);
    const [currency, setCurrency] = useState("");

    const [addressSet, setAddressSet] = useState(false);
    const [roomsSet, setRoomsSet] = useState(false);
    const [priceSet, setPriceSet] = useState(false);

    useEffect(() => {
        const getContent = async () => {
            try {
                const response = await axios.get(`${WEBSITE_URL}`, {
                    params: { username, art, title },
                });
                const responseContact = await axios.get(WEBSITE_URL_CONTACT, {
                    params: {
                        username: username,
                        key: 'city', // specify the column you want to retrieve
                    },
                });
                const responseCountry = await axios.get(WEBSITE_URL_CONTACT, {
                    params: {
                        username: username,
                        key: 'country', // specify the column you want to retrieve
                    },
                });
                const responseStreet = await axios.get(WEBSITE_URL_CONTACT, {
                    params: {
                        username: username,
                        key: 'street', // specify the column you want to retrieve
                    },
                });
                const responseNumber = await axios.get(WEBSITE_URL_CONTACT, {
                    params: {
                        username: username,
                        key: 'number', // specify the column you want to retrieve
                    },
                });
                const responseZip = await axios.get(WEBSITE_URL_CONTACT, {
                    params: {
                        username: username,
                        key: 'zip', // specify the column you want to retrieve
                    },
                });
                const responsePostBus = await axios.get(WEBSITE_URL_CONTACT, {
                    params: {
                        username: username,
                        key: 'postBus', // specify the column you want to retrieve
                    },
                });

                setCity(responseContact.data.city);
                setCountry(responseCountry.data.country);
                setStreet(responseStreet.data.street);
                setNumber(responseNumber.data.number);
                setZip(responseZip.data.zip);
                setPostBus(responsePostBus.data.postBus);

                if (response.data.entry) {
                    // setAccomodationType(response.data.entry.accomodationType);
                    // setGuests(response.data.entry.guests);
                    setBedrooms(response.data.entry.bedrooms);
                    setBathrooms(response.data.entry.bathrooms);
                    setDogs(response.data.entry.dogs);
                    setWheelchair(response.data.entry.wheelchair);
                    setParking(response.data.entry.parking);
                    setChildrenBed(response.data.entry.childrenBed);
                    setSingleBeds(response.data.entry.singleBeds);
                    setDoubleBeds(response.data.entry.doubleBeds);
                    setSofaBeds(response.data.entry.sofaBeds);
                    setCheckIn(response.data.entry.checkIn);
                    setCheckOut(response.data.entry.checkOut);
                    setWifi(response.data.entry.wifi);
                    setLowerPrice(response.data.entry.lowerPrice);
                    setHigherPrice(response.data.entry.higherPrice);
                    setCleaningIncluded(response.data.entry.cleaningIncluded);
                    setcityTaxIncluded(response.data.entry.cityTaxIncluded);
                    setSelfCheckIn(response.data.entry.selfCheckIn);
                    setNumNights(response.data.entry.numNights);
                    setCurrency(response.data.entry.currency);
                }
            } catch (err) {
                console.log(err);
            }
        };
        getContent();
    }, [username, art, title]);

    useEffect(() => {
        if (street !== contactDefaultEntries.street &&
            number !== contactDefaultEntries.number &&
            postBus !== contactDefaultEntries.postBus &&
            zip !== contactDefaultEntries.zip &&
            city !== contactDefaultEntries.city &&
            country !== contactDefaultEntries.country) {
            setAddressSet(true);
        }
    }, [street, number, postBus, zip, city, country])

    useEffect(() => {
        if (bathrooms !== accFormDefaultEntries.bathrooms &&
            bedrooms !== accFormDefaultEntries.bedrooms &&
            singleBeds !== accFormDefaultEntries.singleBeds &&
            doubleBeds !== accFormDefaultEntries.doubleBeds &&
            sofaBeds !== accFormDefaultEntries.sofaBeds) {
            setRoomsSet(true);
        }
    }, [bathrooms, bedrooms, singleBeds, doubleBeds, sofaBeds])

    useEffect(() => {
        if (lowerPrice !== accFormDefaultEntries.lowerPrice && higherPrice !== accFormDefaultEntries.higherPrice) {
            setPriceSet(true);
        } else {
            setPriceSet(false);
        }
    }, [lowerPrice, higherPrice])

    return (
        <div>
            {((!priceSet || !roomsSet || !addressSet) && auth?.role) && <div className='hint-msg'>{t("hints.missing_acc_details")}</div>}
            <h2>{t("accommodation_page.practicalities")}</h2>
            <div>
                <div className='p' >
                    <div className="row">
                        <div className='pract-column'>
                            <h3 className='practicalities'>{t("accommodation_page.checkIn")}</h3>
                            {t("accommodation_page.earliest")} <span>{new Date(`1970-01-01T${checkIn}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                        </div>
                        <div className='pract-column'>
                            <h3 className='practicalities'>{t("accommodation_page.checkOut")}</h3>
                            {t("accommodation_page.latest")} <span>{new Date(`1970-01-01T${checkOut}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className='pract-column'>
                            <h3 className='practicalities'> {t("accommodation_page.minNights")}</h3>
                            {numNights}
                        </div>
                        <div className='pract-column'>
                            <h3 className='practicalities'>{t("accommodation_page.selfCheckIn")}</h3>
                            {selfCheckIn ? (
                                <div> {t("services.yes")} </div>
                            ) : (
                                <div>  {t("services.no")}  </div>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className='pract-column'>
                            <h3 className='practicalities'> {t("accommodation_page.address")} </h3>
                            {addressSet && <div>
                                {street} {number}, {postBus} <br />
                                {zip}, {city}, {country}<br />
                            </div>}
                        </div>
                        <div className='pract-column'>
                            <h3 className='practicalities'> {t("accommodation_page.service")} </h3>
                            {wifi ? (
                                <div>{t("accommodation_page.wifi")}</div>
                            ) : (
                                <></>
                            )}
                            {parking ? (
                                <div>{t("accommodation_page.parking")}</div>
                            ) : (
                                <div>{t("accommodation_page.noParking")}</div>
                            )}
                            {dogs ? (
                                <div>{t("accommodation_page.dogs")}</div>
                            ) : (
                                <div>{t("accommodation_page.noDogs")}</div>
                            )}
                            {childrenBed ? (
                                <div>{t("accommodation_page.childrenBed")}</div>
                            ) : (
                                <div>{t("accommodation_page.noChildrenBed")}</div>
                            )}
                            {wheelchair ? (
                                <div>{t("accommodation_page.wheelchair")}</div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className='pract-column border-white'>
                            <h3 className='practicalities'> {t("accommodation_page.rooms")} </h3>
                            <div>{t("accommodation_page.bedrooms")} <span>{bedrooms === accFormDefaultEntries.bedrooms ? accommodationFormDefaultDisplay.bedrooms : bedrooms}</span></div>
                            {roomsSet && <div>
                                {singleBeds !== 0 && <div>{t("accommodation_page.singleBeds")} <span>{singleBeds}</span></div>}
                                {doubleBeds !== 0 && <div>{t("accommodation_page.doubleBeds")}<span>{doubleBeds}</span></div>}
                                {sofaBeds !== 0 && <div>{t("accommodation_page.sofaBeds")} <span>{sofaBeds}</span></div>}
                            </div>}
                            <div>{t("accommodation_page.bathrooms")}<span>{bathrooms === accFormDefaultEntries.bathrooms ? accommodationFormDefaultDisplay.bathrooms : bathrooms}</span></div>
                        </div>
                        <div className='pract-column'>
                            <h3 className='practicalities'> {t("accommodation_page.prices")} </h3>
                            <div>{t("accommodation_page.vary")} {priceSet ? <span>{lowerPrice} {currency} </span> : '-'} {t("accommodation_page.and")} {priceSet ? <span> {higherPrice} {currency} </span> : '-'} {t("accommodation_page.pernight")} </div>
                            {cityTaxIncluded ? (
                                <div> + {t("accommodation_page.cityTax")}</div>
                            ) : (
                                <div> - {t("accommodation_page.noCityTax")}</div>
                            )}
                            {cleaningIncluded ? (
                                <div> + {t("accommodation_page.cleaning")}</div>
                            ) : (
                                <div> - {t("accommodation_page.noCleaning")}</div>
                            )}
                        </div>
                    </div>



                </div>
            </div>

            <div>




            </div>
        </div>
    );
};

export default Practicalities;



