import React from 'react'
import './dashboard.css'
import AccommodationList from './AccommodationInfoForm';
import ContactInfoForm from './ContactInfoForm';
import StylesForm from './StylesForm';
import TandC from './TandC';
import { useTranslation } from 'react-i18next';

export default function Dashboard(props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="default">
        <div className="stripe">
          <div className="contact-title">
            <h1>{t("dashboard")}</h1>
          </div>
        </div>
        <hr></hr>
        <div className="container">
          <ContactInfoForm />
        </div>
        <hr></hr>
        <div className="container">
          <AccommodationList />
        </div>
        <hr></hr>
        <div className="container">
          <TandC />
        </div>
        <hr></hr>
        <div className="container">
          <StylesForm />
        </div>
      </div>
    </>
  );
};

