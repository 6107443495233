import { createContext, useState } from "react";

const AuthContext = createContext({});
const USERNAME = process.env.REACT_APP_USERNAME

const getHost = function () {
    let host = process.env.NODE_ENV === "development" ? USERNAME : window.location.host;
    if (host.startsWith("www.")) {
        host = host.substring(4)
    }
    return host;
}

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [username, setUsername] = useState(getHost);

    return (
        <AuthContext.Provider value={{ auth, setAuth, username, setUsername }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;