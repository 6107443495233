import React, { useState } from 'react';
import { defaultColors } from './colors';

const ColorChooser = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(props.current);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='colors-dropdown-container form-label'>
            <div className="dropdown-toggle" onClick={toggleDropdown}>
                {selectedOption ? selectedOption : <></>}
            </div>
            {(isOpen && defaultColors !== null) && (
                <div className='colors-dropdown' >
                    {defaultColors.map((color) => (
                        <div className='colors-dropdown-element' onClick={() => {
                            setSelectedOption(color.name);
                            setIsOpen(false);
                            props.onSetBackground(color.background);
                            props.onSetNavigation(color.navigation);
                            props.onSetAccent(color.accent);
                            props.onSetButton(color.button)
                        }}>
                            {color.name}
                            <div className='color-sample-container'>
                                <span className='color-sample' style={{ backgroundColor: color.background }}></span>
                                <span className='color-sample' style={{ backgroundColor: color.navigation }}></span>
                                <span className='color-sample' style={{ backgroundColor: color.accent }}></span>
                                <span className='color-sample' style={{ backgroundColor: color.button }}></span>
                            </div>
                        </div>
                    ))}
                </div>
            )
            }
        </div >
    );
};

export default ColorChooser;
