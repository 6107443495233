import Suggestion from "./Suggestion";
import axios from "../../api/axios";
import { useCallback, useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import SuggestionEdit from "./SuggestionEdit";
import LanguageDropDown from "../dynamic/LanguageDropDown";
import { BsPencil } from "react-icons/bs";
import TagFilter from "./TagFilter";
import { CancelButton, DeleteButton, EditButtonLarge } from "../buttons/Buttons";

const WEBSITE_URL = '/websitejson';
const LANG_URL = '/contact';


const fetchLanguages = async (username, setLanguages) => {
  // get languages array
  await axios.get(`${LANG_URL}`,
    {
      params: {
        username: username,
        art: "nl",
        title: "contact"
      },
    }).then((res) => {
      setLanguages(res?.data?.entry?.languages?.split(","))
    }).catch(function (err) {
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', err.message);
      }
    });
}


export default function SuggestionsEdit() {
  //global states
  const title = "reviews-suggestions";
  const { username } = useAuth();
  const { i18n, t } = useTranslation();
  const [art, setArt] = useState(i18n.language);

  // page navigation
  const [editing, setEditing] = useState(false);
  const [editingId, setEditingId] = useState(-1);

  // page content
  const [suggJSON, setSuggJSON] = useState({})
  const [languages, setLanguages] = useState([]);
  const [currentEditSuggestion, setCurrentEditSuggestion] = useState({})

  // page states
  const [filterTags, setFilterTags] = useState([]);

  // change art if i18n.language changes
  useEffect(() => {
    setArt(i18n.language)
  }, [i18n.language])

  // Get from DB
  const fetchData = useCallback(async () => {
    // create skeleton entry in database
    function createNewSuggJSON() {
      let newSuggJSON = {}
      languages.forEach((lang) => {
        newSuggJSON[lang] = []
      })
      return newSuggJSON
    }

    // get suggestions as a json object
    await axios.get(`${WEBSITE_URL}`,
      {
        params: {
          username: username,
          art: "nl",
          title: title
        },
      }).then((res) => {
        if (Object.keys(JSON.parse(res?.data?.entry?.content)).length !== 0) {
          setSuggJSON(JSON.parse(res?.data?.entry?.content))
        } else {
          setSuggJSON(createNewSuggJSON())
        }
      }).catch(function (err) {
        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(err.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', err.message);
        }
      });
  }, [languages, username])

  // fetch content on initial load
  useEffect(() => {
    async function initialFetch() {
      await fetchData();
    }
    initialFetch();
  }, [fetchData])

  useEffect(() => {
    async function initialFetch() {
      await fetchLanguages(username, setLanguages)
    }
    initialFetch()
  }, [username])

  // Upload to DB
  const uploadData = async () => {
    // put suggestions as a json object
    await axios.put(`${WEBSITE_URL}`,
      {
        username: username,
        art: "nl",
        title: title,
        content: suggJSON
      }).catch(function (err) {
        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(err.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', err.message);
        }
      });
    return true
  }

  // delete suggestion with target ID from local array of suggestions
  function deleteTargetIDs(obj, targetId) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = obj[key].filter(obj => obj.id !== targetId)
      }
    }
    return obj;
  }

  // add suggestion to local arrays of suggestions. 
  // objects in arrays corresponding to different languages differ only in title and intro
  function addSuggestion(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === art) {
          obj[key].push(currentEditSuggestion)
        } else {
          const newSuggestion = {
            "id": currentEditSuggestion.id,
            "title": "",
            "intro": "",
            "address": currentEditSuggestion.address,
            "tel": currentEditSuggestion.tel,
            "weblink": currentEditSuggestion.weblink,
            "tags": currentEditSuggestion.tags
          }
          obj[key].push(newSuggestion)
        }
      }
    }
    return obj;
  }

  // update objects in arrays of suggestions. 
  // for fields "title" and "intro" only update entry in current language
  // for other fields update all languages
  function updateSuggestion(obj, targetId) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === art) {
          obj[key].forEach(function (element, index) {
            if (element.id === targetId) {
              this[index] = currentEditSuggestion
            }
          }, obj[key])
        } else {
          obj[key].forEach(function (element, index) {
            if (element.id === targetId) {
              this[index] = {
                "id": currentEditSuggestion.id,
                "title": element.title,
                "intro": element.intro,
                "address": currentEditSuggestion.address,
                "tel": currentEditSuggestion.tel,
                "weblink": currentEditSuggestion.weblink,
                "tags": currentEditSuggestion.tags
              }
            }
          }, obj[key])
        }
      }
    }
  }

  // delete entry with targetId from local JSON and update database
  const handleDeleteClick = async (targetId) => {
    setSuggJSON(deleteTargetIDs(suggJSON, targetId))
    const uploaded = await uploadData();
    if (!uploaded) {
      console.log("SOMETHING WENT WRONG WITH DELETE")
    }
    await fetchData();
  }

  // receive new ID (highest existing ID + 1)
  const getNewId = () => {
    const suggestions = suggJSON[i18n.language]
    let id = -1
    if (suggestions === null) {
    } else {
      id = suggestions.length
      const ids = suggestions.map(suggestion => suggestion.id)
      if (ids.indexOf(id) >= 0) {
        id = Math.max(...ids) + 1
      }
    }
    return id
  }

  // add new entry to local JSON and update database
  const handleAddClick = async (e) => {
    e.preventDefault();
    // add new ID
    currentEditSuggestion.id = getNewId()

    // update local JSON
    setSuggJSON(addSuggestion(suggJSON))

    // upload local JSON to DB
    const uploaded = await uploadData();
    if (!uploaded) {
      console.log("SOMETHING WENT WRONG ADDING")
    }

    // clean up
    setCurrentEditSuggestion({})
    setEditingId(-1)
  }

  // update entry in local JSON and updated database
  const handleUpdateClick = async (e) => {
    e.preventDefault();
    updateSuggestion(suggJSON, editingId)

    // upload local JSON to DB
    const uploaded = await uploadData();
    if (!uploaded) {
      console.log("SOMETHING WENT WRONG UPDATING")
    }

    setCurrentEditSuggestion({})
    setEditingId(-1)
  }

  const handleOuterCancelClick = () => {
    setCurrentEditSuggestion({})
    setEditingId(-1)
    setEditing(false)
  }

  const handleInnerCancelClick = () => {
    setCurrentEditSuggestion({})
    setEditingId(-1)
  }

  return (
    <div>
      <h2
        style={{ marginBottom: "30px" }}
        onDoubleClick={() => { setEditing(true) }}>
        {editing ? (
          <div className='edit-container'>
            <CancelButton
              buttonText={t("button.cancel")}
              onClick={handleOuterCancelClick}
            />
            <LanguageDropDown
              setArt={setArt}
              art={i18n.language}
            />
          </div>
        ) : (
          <div>
            <span onClick={() => setEditing(true)} className='edit-text'>
              <BsPencil size="25px" />
            </span>
          </div>
        )}
        {t("suggestions_page.suggestions")}
      </h2>
      {!editing && <TagFilter
        filterTags={filterTags}
        setFilterTags={setFilterTags}
      />}
      {suggJSON[i18n.language]?.length === 0 && <div className='hint-msg'>{t("hints.no_sugg")}</div>}
      {editing ?
        (
          <div>
            <div className="row">
              {suggJSON[editing ? art : i18n.language]?.map((suggestion) => {
                return (
                  <div key={suggestion.id}>
                    {
                      editingId === suggestion.id ?
                        (
                          < SuggestionEdit
                            suggestion={currentEditSuggestion}
                            setSuggestion={setCurrentEditSuggestion}
                            handleCancelClick={handleInnerCancelClick}
                            handleSaveClick={handleUpdateClick}
                            key={suggestion.id}
                          />
                        ) : (
                          <div key={suggestion.id} style={{ display: "flex", flexDirection: "column" }}>
                            <div className='edit-container' style={{ display: "block", marginTop: "20px", marginBottom: "-10px" }}>
                              <EditButtonLarge
                                buttonText={t("button.edit")}
                                onClick={() => { setEditingId(suggestion.id); setCurrentEditSuggestion(suggestion) }}
                              />
                              <DeleteButton
                                buttonText={t("button.delete")}
                                onClick={() => handleDeleteClick(suggestion.id)}
                              />
                            </div>
                            <div className="sugg-edit-container" >
                              <Suggestion
                                key={suggestion.id}
                                suggestion={suggestion}
                                filterTags={filterTags}
                                setFilterTags={setFilterTags}
                              />
                            </div>
                          </div>
                        )
                    }
                  </div>)
              }
              )}
            </div>
            {(editingId === -1) && (
              <SuggestionEdit
                suggestion={currentEditSuggestion}
                setSuggestion={setCurrentEditSuggestion}
                handleCancelClick={handleInnerCancelClick}
                handleSaveClick={handleAddClick}
              />
            )}
          </div>
        ) : (
          <div className="sugg-edit-container">
            {suggJSON[i18n.language]?.map((suggestion) => {
              return (<Suggestion suggestion={suggestion} filterTags={filterTags} setFilterTags={setFilterTags} key={suggestion.id} />)
            })}
          </div>
        )
      }
    </div>
  )
}