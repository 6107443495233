import React, { lazy, Suspense } from "react";
import './about.css';
import FetchContent from '../dynamic/fetchContent';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from "react-i18next";
import FAQ from "./FAQ";
import Practicalities from "./Practicalities";
import ImageContent from "../dynamic/ImageContent";
import Content from "../dynamic/Content";
const FetchImage = lazy(() => import('../dynamic/fetchImage'))
const DogFriendlyHome = lazy(() => import('./DogsStripe'))
const WheelChairStripe = lazy(() => import('./WheelChairStripe'))
const Parking = lazy(() => import('./Parking'))
const ChildrenBed = lazy(() => import('./childrenBed'))

export default function Accommodation() {
    const { username, auth } = useAuth();
    const { t } = useTranslation();

    return (
        <div className='default'>
            <div>
                <Suspense fallback={<h1>Still Loading…</h1>}>
                    {(!auth?.role ?
                        <FetchImage title="accommodation" type="image-about" />
                        :
                        <ImageContent title="accommodation" username={username} type="image-about" />
                    )}
                </Suspense>
                <div className="stripe p">
                    <h1>{t("accommodation_page.accommodation")}</h1>
                    <div className="container">
                        <hr></hr>
                    </div>
                    <div className="p">
                        {(!auth?.role ?
                            <div className="container p ws-pre-wrap">
                                <FetchContent title="p-frontpage" />
                            </div>
                            :
                            <div className="container p ws-pre-wrap">
                                <Content title={"p-frontpage"} styling="p" />
                            </div>
                        )}
                    </div>
                </div>

                <div className="stripe">
                    <Practicalities />
                </div>

                <div className="stripe">
                    <FAQ />
                </div>

                <Suspense fallback={<h1>Still Loading…</h1>}>
                    <DogFriendlyHome />
                    <ChildrenBed />
                    <Parking />
                    <WheelChairStripe />
                </Suspense>

            </div>


        </div>
    )
}
