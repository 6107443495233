import i18n from 'i18next';
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';

const PORT = process.env.REACT_APP_PORT
const getBackend = process.env.NODE_ENV === "development" ? `http://localhost:${PORT}/i18n/{{lng}}.json` : `https://${window.location.host}/i18n/{{lng}}.json`

i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        lng: "en",
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: getBackend,
        }
    });

export default i18n;