import { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import { currencies } from "../../constants";

const WEBSITE_URL = '/dashboard';

const CurrencyDropdown = ({ setCurrency }) => {

    const { username } = useAuth();

    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedCurency, setSelectedCurency] = useState("");

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    }

    const handleLanguageChange = (e) => {
        setSelectedCurency(e);
        setCurrency(e)
        toggleDropdown();
    };

    useEffect(() => {
        const getContent = async () => {
            try {
                const response = await axios.get(`${WEBSITE_URL}`, {
                    params: { username, art: "nl", title: "accomodationForm" },
                });
                if (response.data.entry) {
                    setCurrency(response.data.entry.currency);
                    setSelectedCurency(response.data.entry.currency);
                }

            } catch (err) {
                console.log(err);
            }
        };
        getContent();
    }, [username, setCurrency]);

    return (
        <div >
            {showDropdown ? (
                <div className='currency-dropdown'>
                    <button
                        className="currency-dropdown"
                        onClick={toggleDropdown}
                        type="button"
                    >
                        {selectedCurency} &#9660;
                    </button>
                    {currencies !== null && <div style={{ backgroundColor: "white", position: "absolute", display: "flex", flexDirection: "column", width: "100%" }}>
                        {currencies.map((code) => (
                            <button
                                key={code}
                                value={code}
                                className='drop-currency'
                                type="button"
                                style={{ padding: "5px" }}
                                onClick={(e) => {
                                    handleLanguageChange(e.target.value)
                                }}>
                                {code}
                            </button>
                        ))}
                    </div>}
                </div>
            ) : (
                <div className='currency-dropdown' >
                    <button
                        className="currency-dropdown"
                        onClick={toggleDropdown}
                        type="button">
                        {selectedCurency} &#9660;
                    </button>
                </div>
            )}
        </div>
    )
}

export default CurrencyDropdown;