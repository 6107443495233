import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import { NavLink } from '@mantine/core';
import useAuth from '../../hooks/useAuth';
import { BsPencil } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { SaveButton } from '../buttons/Buttons';
import { defaultLanguage } from '../../constants';

const WEBSITE_URL = '/website';

export default function FileInputForm(props) {
    const title = props.title;
    const { username } = useAuth();
    const [equipment, setEquipment] = useState(props.array);
    const [selectedEquipment, setSelectedEquipment] = useState([]);
    const { auth } = useAuth();
    const [editing, setEditing] = useState(false);
    const { t } = useTranslation();
    const art = defaultLanguage;

    // If there is a language change, take again props.array
    useEffect(() => {
        setEquipment(props.array);
    }, [props.array]);

    useEffect(() => {
        const getContent = async () => {
            try {
                const response = await axios.get(`${WEBSITE_URL}`, {
                    params: { username, art, title },
                });
                if (response.data.entry) {
                    if (response.data.entry.content === 'default text') {
                        setSelectedEquipment([]);
                    } else {
                        const content = JSON.parse(response.data.entry.content);
                        setSelectedEquipment(content);
                    }
                }
            } catch (err) {
                console.error(err);
            }
        };
        getContent();
    }, [username, art, title]);

    const handleEditClick = () => {
        setEditing(true);
    };

    const saveContent = async () => {
        try {
            await axios.put(WEBSITE_URL, {
                username,
                art,
                title,
                content: JSON.stringify(selectedEquipment),
            });
            setEditing(false);
        } catch (err) {
            console.log(err);
        }
    };

    const handleSaveClick = (event) => {
        event.preventDefault();
        saveContent();
    };

    const handleCheckboxChange = (event, index) => {
        const isChecked = event.target.checked;
        setSelectedEquipment(prevSelected => {
            if (isChecked) {
                return [...prevSelected, index];
            } else {
                return prevSelected.filter(eq => eq !== index);
            }
        });
    };

    if (editing) {
        return (
            <div>
                <div>
                    {equipment !== null && <form>
                        {equipment.map((eq, index) => (
                            <label key={eq} style={{ marginRight: '10px', fontSize: '16px' }}>
                                <input
                                    type="checkbox"
                                    id={eq}
                                    value={eq}
                                    checked={selectedEquipment.includes(index)}
                                    onChange={(event) => handleCheckboxChange(event, index)}
                                />
                                {eq}
                            </label>
                        ))}
                    </form>}
                    <SaveButton
                        buttonText={t("button.save")}
                        onClick={(event) => handleSaveClick(event)}
                        type='submit'
                    />
                </div>
            </div>
        );
    }


    return (
        <div>
            {selectedEquipment?.length > 0 ? (
                selectedEquipment?.map(index => (
                    <NavLink label={equipment[index]} key={index}></NavLink>
                ))
            ) : (
                <p>/</p>
            )}
            {(auth?.role ?
                <span onClick={handleEditClick} className='edit-text'>
                    <BsPencil size="25px" />    </span>
                :
                <></>
            )}
        </div>
    );
}