import React from 'react'
import './nav.css'
import {
    NavLink,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faCircleInfo, faLightbulb, faAddressCard, faCalendarDays, faTableColumns } from '@fortawesome/free-solid-svg-icons';


const iconArray = [
    { "icon": faHouse, "ref": "home" },
    { "icon": faCircleInfo, "ref": "accommodation" },
    { "icon": faLightbulb, "ref": "suggestions" },
    { "icon": faAddressCard, "ref": "contact" }

]
const iconArrayAdmin = [
    { "icon": faHouse, "ref": "home" },
    { "icon": faCircleInfo, "ref": "accommodation" },
    { "icon": faLightbulb, "ref": "suggestions" },
    { "icon": faAddressCard, "ref": "contact" },
    { "icon": faTableColumns, "ref": "dashboard" }
]

export default function NavItemsMobile({ isAdmin, calendarUrl }) {

    return (
        <div style={{ fontSize: "5px" }}>
            {!isAdmin
                ?
                <div>
                    {iconArray.map(({ icon, ref }) => (
                        <li key={ref} >
                            <NavLink className="nav-link" to={`${ref}`}>
                                <FontAwesomeIcon icon={icon} />
                            </NavLink>
                        </li>
                    ))
                    }
                    {calendarUrl !== '' ? (
                        <li>
                            <a href={calendarUrl} className="nav-link">
                                <FontAwesomeIcon icon={faCalendarDays} />
                            </a>
                        </li>
                    ) : (
                        <></>
                    )}

                </div>
                :
                <div>
                    {iconArrayAdmin.map(({ icon, ref }) => (
                        <li key={ref} >
                            <NavLink className="nav-link" to={`edit/${ref}`}>
                                <FontAwesomeIcon icon={icon} />
                            </NavLink>
                        </li>
                    ))
                    }
                </div>}
        </div>
    )
}