import React, { useRef, useCallback, useState, useEffect } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { BsPencil } from 'react-icons/bs';
import { BiPlusCircle } from 'react-icons/bi';
import { BiMinusCircle } from 'react-icons/bi';
import LanguageDropDown from '../dynamic/LanguageDropDown';
import { SaveButton, CancelButton, EditButtonLarge, DeleteButton, AddButton } from '../buttons/Buttons';
const WEBSITE_URL = '/websitejson';

function Review({ review, id, expandedReviews, setExpandedReviews }) {
    const isExpanded = expandedReviews.includes(id);

    const toggleExpanded = () => {
        if (isExpanded) {
            setExpandedReviews(expandedReviews.filter((item) => item !== id));
        } else {
            setExpandedReviews([...expandedReviews, id]);
        }
    };
    return (
        <div className="review">
            <p className="review-txt" onClick={toggleExpanded}>
                {review.intro} {isExpanded ? <BiMinusCircle size="25px" /> : <BiPlusCircle size="25px" />}
            </p>
            {isExpanded && <p className="review-txt faq">{review.text}</p>}
        </div>
    );
}

export default function FAQ() {
    const title = "reviews-FAQ";
    const { username, auth } = useAuth();
    const { i18n, t } = useTranslation();

    // Set the language of the post-its
    const [art, setArt] = useState(i18n.language);
    const [expandedReviews, setExpandedReviews] = useState([]);

    // States
    const [editing, setEditing] = useState(false);
    const [editingReviewId, setEditingReviewId] = useState(1);

    // JSON input
    const [text, setText] = useState('');
    const [intro, setIntro] = useState('');

    const [textAdd, setTextAdd] = useState('');
    const [introAdd, setIntroAdd] = useState('');

    const [reviews, setReviews] = useState([
        {
            id: 0,
            intro: '',
            text: '',
        },
    ]);

    const reviewsRef = useRef(reviews);

    useEffect(() => {
        reviewsRef.current = reviews;
    }, [reviews]);

    useEffect(() => {
        if (!editing) {
            setArt(i18n.language)
        }
    }, [i18n.language, editing])

    const updateReviews = useCallback(async () => {
        try {
            if (art !== undefined) {
                const response = await axios.get(`${WEBSITE_URL}`, {
                    params: { username, art: art, title },
                });
                if (response.data.entry) {
                    const reviewsData = JSON.parse(response.data.entry.content);
                    let transformedReviews;
                    if ((reviewsData !== "default text") && (reviewsData !== null)) {
                        transformedReviews = reviewsData.map((review) => ({
                            id: review.id,
                            intro: review.intro,
                            text: review.text
                        }));
                    } else {
                        transformedReviews = reviewsRef.current;
                    }
                    setReviews(transformedReviews);
                    if (editing) {
                        let currentEdit = null;
                        transformedReviews.forEach(function (review) {
                            if (review.id === editingReviewId) {
                                currentEdit = review
                            }
                        });
                        console.log(JSON.stringify(currentEdit));
                        if (currentEdit) {
                            setIntro(currentEdit.intro)
                            setText(currentEdit.text)
                        }
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    }, [username, art, title, editing, editingReviewId])

    useEffect(() => {
        const update = async () => {
            await updateReviews();
        }
        update();
    }, [updateReviews]);

    const handleEditClick = () => {
        setEditing(true);
    };

    const handleDeleteClick = async (id) => {
        try {
            if (art !== undefined) {
                setReviews(reviews.filter((review) => review !== id));
                const reviewSmall = reviews.filter((review) => review !== id);
                await axios.put(WEBSITE_URL, {
                    username,
                    art,
                    title,
                    content: reviewSmall,
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleAddClick = async (event) => {
        event.preventDefault();
        const newReview = {
            id: Date.now(), // use the current timestamp as the unique id
            intro: introAdd,
            text: textAdd,
        };
        setReviews([...reviews, newReview]);
        try {
            if (art !== undefined) {
                await axios.put(WEBSITE_URL, {
                    username,
                    art,
                    title,
                    content: [...reviews, newReview],
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleSaveClick = async (event) => {
        event.preventDefault();
        const newReview = {
            id: editingReviewId, // use the current timestamp as the unique id
            intro: intro,
            text: text,
        };
        const oldReviewIndex = reviews.findIndex(review => review.id === editingReviewId);
        const updatedReviews = [...reviews];
        if (oldReviewIndex !== -1) {
            updatedReviews.splice(oldReviewIndex, 1, newReview);
        } else {
            updatedReviews.push(newReview);
        }
        setReviews(updatedReviews);
        setEditingReviewId(1);
        try {
            await axios.put(WEBSITE_URL, {
                username,
                art,
                title,
                content: updatedReviews,
            });
        } catch (err) {
            console.log(err);
        }
        setText("");
        setIntro("");
        updateReviews();
    };

    const handleCancelClick = () => {
        updateReviews();
        setEditing(false);
    };

    const handleEditClickReview = (id) => {
        setEditingReviewId(id.id);
        setText(id.text);
        setIntro(id.intro);
    };

    const editReview = () => {
        return (
            <div className='container review-bg'>
                <form className="review">
                    <div className="form-row">
                        <label htmlFor="category"><p style={{ fontSize: '16px' }}>{t("faq.add")}</p></label>
                        <input
                            type="text"
                            id="title"
                            value={intro}
                            style={{ fontSize: '16px', width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc", backgroundColor: "#f5f5f5", color: "#333" }}
                            onChange={(event) => setIntro(event.target.value)}
                        />
                    </div>
                    <div className="form-row">
                        <label htmlFor="text"><p style={{ fontSize: '16px' }}>{t("faq.answer")}</p></label>
                        <textarea
                            id="text"
                            value={text}
                            style={{ fontSize: '16px', width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc", backgroundColor: "#f5f5f5", color: "#333", height: "auto", minHeight: "200px" }}
                            onChange={(event) => setText(event.target.value)}
                        ></textarea>
                    </div>
                    <div className=''>
                        <SaveButton
                            buttonText={t("button.save")}
                            onClick={(event) => { setText(""); setIntro(""); handleSaveClick(event) }}
                        />
                        <CancelButton
                            buttonText={t("button.cancel")}
                            onClick={() => { setEditingReviewId(1); setText(""); setIntro("") }}
                        />
                    </div>
                </form>
            </div>
        )
    }

    return (
        <div>
            {(!auth?.role ?
                <div>
                    <h2>{t("accommodation_page.FAQ")}</h2>
                </div>
                :
                <div>
                    <h2
                        style={{ marginBottom: "30px" }}
                        onDoubleClick={handleEditClick}>
                        {editing ? (
                            <div className='edit-container'>
                                <CancelButton
                                    buttonText={t("button.cancel")}
                                    onClick={handleCancelClick}
                                />
                                <LanguageDropDown setArt={setArt} art={art} />
                            </div>
                        ) : (
                            <div>
                                <span onClick={handleEditClick} className='edit-text'>
                                    <BsPencil size="25px" />
                                </span>
                            </div>
                        )}
                        {t("accommodation_page.FAQ")}
                    </h2>
                    {(reviews.length === 0) && <div className='hint-msg'>{t("hints.no_faq")}</div>}
                </div>

            )}
            {
                (editing) ?
                    (
                        <div>
                            {reviews.map((review) => (
                                <div key={review.id} className='faq-container'>
                                    {editingReviewId === review.id ? (
                                        editReview(review)
                                    ) : (
                                        <div className="review p review-bg faq-container" >
                                            <p className="review-txt">
                                                {review.intro}
                                            </p>
                                            <p className="review-txt faq">{review.text}</p>
                                            <div className='edit-container'>
                                                <EditButtonLarge
                                                    buttonText={t("button.edit")}
                                                    onClick={() => handleEditClickReview(review)}
                                                />
                                                <DeleteButton
                                                    buttonText={t("button.delete")}
                                                    onClick={() => handleDeleteClick(review)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                            {editingReviewId === 1 ? (
                                <div className='container review-bg'>
                                    <form className="review" style={{ overflow: "scroll" }}>
                                        <div className="form-row">
                                            <label htmlFor="category"><p style={{ fontSize: '16px' }}>{t("faq.add")}</p></label>
                                            <input
                                                type="text"
                                                id="title"
                                                value={introAdd}
                                                style={{ width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc", backgroundColor: "#f5f5f5", color: "#333" }}
                                                onChange={(event) => setIntroAdd(event.target.value)}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <label htmlFor="text"><p style={{ fontSize: '16px' }}>{t("faq.answer")}</p></label>
                                            <textarea
                                                id="text"
                                                value={textAdd}
                                                style={{ width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc", backgroundColor: "#f5f5f5", color: "#333" }}
                                                onChange={(event) => setTextAdd(event.target.value)}
                                            ></textarea>
                                        </div>
                                        <div className='edit-container'>
                                            <AddButton
                                                buttonText={t("button.add")}
                                                onClick={(event) => { handleAddClick(event); setTextAdd(""); setIntroAdd("") }}
                                            />
                                        </div>
                                    </form>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>)
                    : (
                        <div>
                            {reviews.map((review, index) => (
                                <div key={index} className='review-bg p'>
                                    <Review review={review} id={index} expandedReviews={expandedReviews} setExpandedReviews={setExpandedReviews} />
                                </div>
                            ))}
                        </div>
                    )}
        </div>
    );
}

