import React, { useState, useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import './index.css';
import Dashboard from './components/dashboard/Dashboard';

import RequireAuth from './context/RequireAuth';
import PersistLogin from './context/PersistLogin';
import Layout from './Layout';
import LoginForm from './components/login/LoginForm';

import Accommodation from './components/accommodation/Accommodation';
import SuggestionsMain from './components/suggestions/SuggestionsMain';
import Contact from './components/contact/Contact';
import EnLegalConditions from './components/legal/Legal';
import Home from './components/home/Home';

import useAuth from './hooks/useAuth';
import axios from './api/axios';

import { defaultLanguage } from './constants';

const WEBSITE_URL = '/styles';
const WEBSITE_URL_ACC = '/dashboard';


function lightenHexColor(hexColor, percent) {
  // Convert hex color code to RGB values
  const red = parseInt(hexColor.slice(1, 3), 16);
  const green = parseInt(hexColor.slice(3, 5), 16);
  const blue = parseInt(hexColor.slice(5, 7), 16);

  // Calculate the new RGB values by increasing each component by the specified percentage
  const newRed = Math.round(red + (255 - red) * percent / 100);
  const newGreen = Math.round(green + (255 - green) * percent / 100);
  const newBlue = Math.round(blue + (255 - blue) * percent / 100);

  // Convert the new RGB values back to a hex color code
  const newHexColor = "#" + ((1 << 24) + (newRed << 16) + (newGreen << 8) + newBlue).toString(16).slice(1);

  return newHexColor;
}

const App = () => {
  const [color1, setColor1] = useState('#F8EDE3');
  const [color2, setColor2] = useState('#594545');
  const [color3, setColor3] = useState('#C58940');
  const [color4, setColor4] = useState('#289bc1');
  const [lighterColor3, setLighterColor3] = useState('');
  const [paragraphColor, setParagraphColor] = useState('');
  const [fontHeading, setFontHeading] = useState('');
  const [fontText, setFontText] = useState('');

  const { username } = useAuth();

  const [stylesLoaded, setStylesLoaded] = useState(false);
  const art = defaultLanguage;
  const title = "accomodationForm";

  useEffect(() => {
    const getContent = async () => {
      await axios.get(`${WEBSITE_URL_ACC}`, {
        params: { username, art, title },
      }).then((res) => {
        if (res.data.entry) {
          document.title = res.data.entry.nameAccommodation;
        }
      }).catch(err => {
        if (err?.response) {
          console.error('HTTP error! Status:', err.response.status);
          console.error('Response:', err.response);
        } else if (err.request) {
          console.error('No response received:', err.request);
        } else {
          console.error('Error setting up the request:', err.message);
        }
      });
    };
    getContent();
  }, [username, art, title]);

  useEffect(() => {
    const getContent = async () => {
      await axios.get(`${WEBSITE_URL}`, {
        params: { username },
      }).then((res) => {
        setColor1(res?.data?.entry?.color1);
        setColor2(res?.data?.entry?.color2);
        setColor3(res?.data?.entry?.color3);
        setColor4(res?.data?.entry?.color4);
        setFontHeading(res?.data?.entry?.fontHeading);
        setFontText(res?.data?.entry?.fontText);
        setParagraphColor(lightenHexColor(res?.data?.entry?.color2, 20));
        setLighterColor3(lightenHexColor(res?.data?.entry?.color3, 30));
        setStylesLoaded(true);
      }).catch(err => {
        if (err?.response) {
          console.error('HTTP error! Status:', err.response.status);
          console.error('Response:', err.response);
        } else if (err.request) {
          console.error('No response received:', err.request);
        } else {
          console.error('Error setting up the request:', err.message);
        }
      });
    };
    getContent();
  }, [username]);

  const style = {
    '--color-bg': color1,
    '--color-nv': color2,
    '--color-headings': color2,
    '--color-stripe': color3,
    '--color-stripe-light': lighterColor3,
    '--color-button': color4,
    '--color-text_paragraph': paragraphColor,
    '--font-fm-ser': fontHeading,
    '--font-fm-ss': fontText,
  };

  return (
    <div style={style}>
      {(stylesLoaded) ? (
        <Routes>
          <Route path='/' element={<Layout />}>
            {/* public routes */}

            <Route key="home" path="/home" element={<Home />} />
            <Route key="accommodation" path="/accommodation" element={<Accommodation />} />
            <Route key="suggestions" path="/suggestions" element={<SuggestionsMain />} />
            <Route key="contact" path="/contact" element={<Contact />} />
            <Route key="terms" path="/terms" element={<EnLegalConditions />} />
            <Route key="login" path="/login" element={<LoginForm />} />

            {/* Redirects */}
            {/* "/" root path redirects to homepage */}
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/suggesties" element={<Navigate to="/suggestions" />} />
            <Route path="/accomodatie" element={<Navigate to="/accommodations" />} />

            {/* protected routes*/}
            <Route element={<PersistLogin />}>
              <Route element={<RequireAuth allowedRole={"User"} />}>
                <Route key="edit-dash" path="/edit/dashboard" element={<Dashboard />} />
                <Route key="edit-home" path="/edit/home" element={<Home />} />
                <Route key="edit-accommodation" path="/edit/accommodation" element={<Accommodation />} />
                <Route key="edit-suggestions" path="/edit/suggestions" element={<SuggestionsMain />} />
                <Route key="edit-contact" path="/edit/contact" element={<Contact />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default App;
