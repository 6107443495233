import { Carousel } from '@mantine/carousel';
import { useCallback, useEffect, useState } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import cloudinaryConfig from '../../config/cloudinary.config';
import imageCompression from 'browser-image-compression';
import { useTranslation } from 'react-i18next';
import { AddImageButton, CancelButton, DeleteButton, EditImageButton, SaveButton } from '../buttons/Buttons';

const WEBSITE_URL = "/image_url"

export default function CarouselContent(props) {
    const galleryTitle = "gallery-" + props.title;
    const [images, setImages] = useState([]);
    const [editing, setEditing] = useState(false);
    const [adding, setAdding] = useState(false);
    const [lastIndex, setLastIndex] = useState(0);
    const [imageObjects, setImageObjects] = useState([]);
    const [overwrite] = useState(false);
    const [status, setStatus] = useState("");

    const maxImages = props.maxImages;
    const [uploadLegal, setUploadLegal] = useState(false);
    const [nofImagesInDB, setNofImagesInDB] = useState(-1);
    const [nofImagesToUpload, setNofImagesToUpload] = useState(0);

    //hooks
    const { username } = useAuth();
    const { t } = useTranslation();

    const updateGallery = useCallback(async () => {
        const url = WEBSITE_URL + "/gallery";
        try {
            const response = await axios.get(url, {
                params: { title: galleryTitle, username }
            });
            if (response.data) {
                // sort by public id (order added)
                if (!(response.data.length === 0)) {
                    const images = response.data.sort((a, b) => {
                        if (a.public_id < b.public_id) {
                            return -1;
                        }
                        if (a.public_id > b.public_id) {
                            return 1;
                        }
                        return 0;
                        //bring urls in array form
                    }).map((item, idx) => {
                        return (
                            {
                                url: item.url,
                                public_id: item.public_id,
                                idx: idx
                            }
                        )
                    })
                    const lastIndex = Math.max(...images.map(obj => obj.public_id.split('-').slice(-1)));
                    if (lastIndex) {
                        setLastIndex(lastIndex);
                    }
                    setNofImagesInDB(images.length);
                    setImageObjects(images);
                }
            }
        } catch (err) {
            console.error(err);
        }
    }, [galleryTitle, username])

    useEffect(() => {
        const update = async () => {
            await updateGallery(lastIndex, images, status);
        }
        update();
    }, [lastIndex, images, status, updateGallery]);

    useEffect(() => {
        const update = async () => {
            await updateGallery(lastIndex, images, status);
        }
        update();
    }, [updateGallery, images, lastIndex, status])

    useEffect(() => {
        const uploadLegal = ((nofImagesInDB + nofImagesToUpload) <= maxImages)
        setUploadLegal(uploadLegal)
        if (!(nofImagesInDB + nofImagesToUpload <= maxImages)) {
            setStatus(t("warnings.max_images_1.1") + String(maxImages) + t("warnings.max_images_1.2") + String(maxImages - nofImagesInDB) + t("warnings.max_images_1.3"))
        } else if (nofImagesInDB === maxImages) {
            setStatus(t("warnings.max_images_2.1") + String(maxImages) + t("warnings.max_images_2.2"))
        } else {
            setStatus(t("hints.image_upload"))
        }
    }, [setUploadLegal, maxImages, nofImagesInDB, nofImagesToUpload, t])


    async function handleFileUpload(event) {
        const newImages = [...images];
        const files = event.target.files;
        const maxSizeMB = 0.3;
        const maxWidthOrHeight = 1920;

        const options = {
            maxSizeMB,
            maxWidthOrHeight,
        };

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            // Check if file size is larger than 0.3MB
            if (file.size > maxSizeMB * 1024 * 1024) {
                const reader = new FileReader();
                try {
                    const compressedFile = await imageCompression(file, options);
                    reader.readAsDataURL(compressedFile);
                    reader.onload = () => {
                        newImages.push(reader.result);
                        setImages(newImages);
                    };
                } catch (error) {
                    console.log(error);
                }
            } else {
                // File size is smaller than or equal to 0.3MB, use the original file
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    newImages.push(reader.result);
                    setImages(newImages);
                };
            }
        }

        setNofImagesToUpload(files.length);
    }


    const handleDelete = async (event) => {
        const public_id = event.target.id;
        // get signature on public id
        const signResponse = await axios.get('/signuploadform', {
            params: {
                publicID: public_id,
            }
        });
        const signData = signResponse.data;
        const url = cloudinaryConfig.BASE_URL + signData.cloudName + cloudinaryConfig.DELETE_SUFFIX;

        if (signData.signature && url) {
            //delete from cloudinary
            await axios.post(url, {
                "signature": signData.signature,
                "api_key": signData.apiKey,
                "cloud_name": signData.cloudName,
                "timestamp": signData.timestamp,
                "public_id": public_id,
                "invalid": true
            })
                .then(async (res) => {
                    //delete from db
                    await axios.delete(WEBSITE_URL, {
                        data: {
                            username: username,
                            title: galleryTitle,
                            public_id: public_id
                        }
                    })
                })
        }
        await updateGallery();
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const handleSubmit = async (event) => {
        event.preventDefault();
        setStatus("Uploading...");
        const folder = props.username + "/" + galleryTitle;

        if (images.length < 1) {
            setStatus(t("hints.min_one_image"));
        } else {
            try {
                const uploadPromises = images.map(async (image, index) => {
                    const newIndex = index + Number(!overwrite) * lastIndex + 1;
                    // get signature for upload
                    const signResponse = await axios.get('/signuploadform', {
                        params: {
                            folder: folder,
                            publicID: `${props.title}-${newIndex}`,
                        }
                    });
                    const signData = signResponse.data;
                    const url = cloudinaryConfig.BASE_URL + signData.cloudName + cloudinaryConfig.UPLOAD_SUFFIX;

                    const formData = new FormData();
                    formData.append("api_key", signData.apiKey);
                    formData.append("cloud_name", signData.cloudName);
                    formData.append("file", image);
                    formData.append("folder", folder);
                    formData.append("public_id", `${props.title}-${newIndex}`);
                    formData.append("signature", signData.signature);
                    formData.append("timestamp", signData.timestamp);

                    if (url && formData) {
                        const uploadResponse = await axios.post(url, formData);
                        await axios.put('/image_url', {
                            username: props.username,
                            title: galleryTitle,
                            url: uploadResponse.data.secure_url,
                            public_id: uploadResponse.data.public_id
                        });
                    }
                });

                // Wait for all uploads to finish
                await Promise.all(uploadPromises);

                setStatus(t("success.upload"));
                await delay(1000);
                setImages([]);
                await updateGallery();
                setAdding(false);
            } catch (error) {
                console.error(error);
                setStatus("Error occurred during upload.");
            }
        }
    };


    const handleOuterCancel = () => {
        setAdding(false);
        setEditing(false);
        setStatus("");
    }

    const handleInnerCancel = () => {
        setAdding(false);
        setStatus("");
        setNofImagesToUpload(0);
    }

    return (
        <div className="container container-carousel">
            {(((images.length === 0) && (nofImagesInDB === 0)) || (nofImagesInDB === -1)) && <div className='hint-msg'>
                {t("hints.no_images_in_gallery")}
            </div>}
            {editing ? (
                <div>
                    {(!adding) && <div>
                        <AddImageButton
                            buttonText={t("button.add")}
                            disable={(!uploadLegal || (nofImagesInDB >= maxImages))}
                            onClick={() => { setAdding(true); setStatus("Please choose a file to upload!") }}
                        />
                        <CancelButton
                            buttonText={t("button.cancel")}
                            onClick={handleOuterCancel}
                        />
                    </div>}
                    {
                        adding && <form onSubmit={handleSubmit}>
                            <span className='img-upload-form'>
                                <input type="file" multiple onChange={handleFileUpload} />
                            </span>
                            <SaveButton
                                buttonText={t("button.upload")}
                                onClick={() => { }}
                                enabled={uploadLegal}
                                type='submit'
                            />
                            <CancelButton
                                buttonText={t("button.cancel")}
                                onClick={handleInnerCancel}
                            />
                            {status && <span className='status-info'>
                                {status}
                            </span>}
                        </form>
                    }
                    {((nofImagesInDB >= maxImages) && uploadLegal) && <span className='status-info'>
                        {status}
                    </span>}
                </div>
            ) : (
                <div>
                    <EditImageButton
                        buttonText={t("button.edit_gallery")}
                        onClick={() => setEditing(true)}
                    />
                </div>
            )
            }
            {
                (imageObjects.length > 0) && <Carousel
                    slideSize="33.3%"
                    withIndicators
                    slideGap="md"
                    breakpoints={[
                        { maxWidth: 'md', slideSize: '50%' },
                        { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
                    ]}
                    loop
                    align="start"
                >
                    {imageObjects.map(
                        (image) => <Carousel.Slide key={image.idx}>
                            {
                                <div className='gallery-image-container'>
                                    <img className='gallery-image' key={image.idx} src={image.url} alt="gallery" />

                                    {editing &&
                                        <>
                                            

                                            <DeleteButton
                                                buttonText={t("button.delete")}
                                                onClick={handleDelete}
                                                id={image.public_id}
                                                type='button'
                                            />
                                        </>
                                    }
                                </div>
                            }
                        </Carousel.Slide>)}
                </Carousel>
            }
        </div>
    )
}