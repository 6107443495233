import { useState, useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import useIsMobile from "../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { defaultLanguage } from "../../constants";
import { contactDefaultEntries } from "../../languages/defaultEntries";
import { CancelButton, EditButtonLarge, ReloadButton, SaveButton, SearchButton } from "../buttons/Buttons";


const mapStyle = {
    width: "90%", height: '500px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: '20px'
}

const mobileMapStyle = {
    width: "95%", height: '300px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: '20px'
}

export default function Map({
    apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    label = "",
    zoom = 16,
    coords = {
        "lat": 47.373090161924445,
        "lng": 8.553088943000699
    },
    useCustomLoc = false,
    adminUser = false,
}) {
    const url = '/contact';
    const art = defaultLanguage;
    const title = "contact";

    const [street, setStreet] = useState('');
    const [number, setNumber] = useState(1);
    const [zip, setZip] = useState(1);
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [defaultEntry, setDefaultEntry] = useState(true);
    const [gmapWin, setGmapWin] = useState(false);
    const [gmapObj, setGmapObj] = useState(null);
    const [markerPos, setMarkerPos] = useState({ "lat": 0, "lng": 0 });
    const [editing, setEditing] = useState(false);
    const [dispLat, setDispLat] = useState(0);
    const [dispLng, setDispLng] = useState(0);
    const [submitted, setSubmitted] = useState(false);

    const mapBox = useRef();
    const markerObj = useRef();
    const props = useRef({ apiKey, label, coords, zoom, useCustomLoc, adminUser });

    const { username } = useAuth();
    const { t } = useTranslation();
    const isMobile = useIsMobile();


    useEffect(() => {
        const getContent = async () => {
            try {
                const res = await axios.get(url, {
                    params: { username, art, title }
                });
                if (res.data.entry) {
                    setStreet(res.data.entry.street);
                    setNumber(res.data.entry.number);
                    setZip(res.data.entry.zip);
                    setCity(res.data.entry.city);
                    setCountry(res.data.entry.country);
                    setMarkerPos({ "lat": parseFloat(res.data.entry.positionLat), "lng": parseFloat(res.data.entry.positionLng) })
                    setDispLat(res.data.entry.positionLat)
                    setDispLng(res.data.entry.positionLng)
                    if (res.data.entry.positionLat !== contactDefaultEntries.lat && res.data.entry.positionLng !== contactDefaultEntries.lng) {
                        setDefaultEntry(false);
                    }
                    setLoaded(true);
                }
            } catch (err) {
                console.log(err);
            }
        };
        getContent();
    }, [art, username]);

    // load map
    useEffect(() => {
        const loader = new Loader({
            apiKey: props.current.apiKey,
            version: "weekly",
            passive: true,
        });
        const abortController = new AbortController({ passive: true });


        (async function () {
            loader.load().then(() => {
                if (
                    !abortController.signal.aborted &&
                    window.google?.maps &&
                    !gmapWin
                ) {
                    setGmapWin(true);
                }
                if (gmapWin && loaded) {
                    try {
                        setGmapObj(
                            new window.google.maps.Map(mapBox.current, {
                                center: markerPos,
                                zoom: 16,
                                passive: true
                            })
                        );
                    } catch (err) {
                        console.log(err)
                    }

                }
            });
        })();

        return () => {
            abortController.abort();
        };

    }, [gmapWin, city, country, number, street, zip, markerPos, loaded]);

    const fetchCoordinates = () => {
        const geocoder = new window.google.maps.Geocoder({ passive: true });
        const address = street + " " + number + " " + zip + " " + city + " " + country;
        const request = { address: address }

        const abortController = new AbortController();

        geocoder
            .geocode(request)
            .then((result) => {
                const { results } = result;
                try {
                    setGmapObj(
                        new window.google.maps.Map(mapBox.current, {
                            center: { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() },
                            zoom: 16,
                            passive: true,
                        })
                    );
                } catch (err) {
                    console.log(err)

                }

                setMarkerPos({ "lat": results[0].geometry.location.lat(), "lng": results[0].geometry.location.lng() })
                setDispLat(results[0].geometry.location.lat())
                setDispLng(results[0].geometry.location.lng())
            })
            .catch((e) => {
                console.log("Geocode was not successful for the following reason: " + e);
            });
        return () => {
            abortController.abort();
        };

    }

    const reloadMap = () => {
        if (gmapObj) {
            try {
                setGmapObj(
                    new window.google.maps.Map(mapBox.current, {
                        center: { lat: markerPos.lat, lng: markerPos.lng },
                        zoom: 16,
                        passive: true,
                    })
                );
            } catch (err) {
                console.log(err)
            }
        }

    }

    // add marker
    useEffect(() => {
        if (gmapObj) {
            markerObj.current = new window.google.maps.Marker({
                position: markerPos,
                map: gmapObj,
                label: props.current.label,
                passive: true,
            })
        }
    }, [markerPos, gmapObj]);

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            await uploadMarkerPos();
            setSubmitted(true);
            setEditing(false);
        } catch (error) {
            setSubmitted(false);
            console.error('Error uploading data:', error);
        }
    }

    const uploadMarkerPos = async () => {
        const msgLat = {
            username: username,
            title: title,
            key: "positionLat",
            value: dispLat,
        }

        const msgLng = {
            username: username,
            title: title,
            key: "positionLng",
            value: dispLng,
        }

        try {
            await axios.put('/contact/key', msgLat)
            await axios.put('/contact/key', msgLng)
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <div>
            {(props.current.adminUser &&
                <div>
                    {editing ? (
                        <div>
                            {defaultEntry && <div className="hint-msg">{t("hints.default_marker")}</div>}
                            <div style={{ display: "inline-flex", marginLeft: "5%" }}>
                                <form onSubmit={handleFormSubmit}>
                                    <div>
                                        <label style={{ fontSize: "16px" }}>{t("map.lat")}</label>
                                        <input
                                            type="number"
                                            step="0.000001"
                                            value={dispLat}
                                            onChange={(event) => {
                                                setMarkerPos({ "lat": parseFloat(event.target.value), "lng": markerPos.lng })
                                                setDispLat(parseFloat(event.target.value))
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <label style={{ fontSize: "16px", marginTop: "8px" }}>{t("map.lng")}</label>
                                        <input
                                            type="number"
                                            step="0.000001"
                                            value={dispLng}
                                            onChange={(event) => {
                                                setMarkerPos({ "lat": markerPos.lat, "lng": parseFloat(event.target.value) })
                                                setDispLng(parseFloat(event.target.value))
                                            }}
                                        />
                                    </div>
                                    <SaveButton
                                        buttonText={t("button.save")}
                                        onClick={() => { }}
                                        type="submit"
                                    />
                                    <CancelButton
                                        buttonText={t("button.cancel")}
                                        onClick={() => {
                                            setEditing(false);
                                            setSubmitted(false);
                                        }}
                                    />
                                    <SearchButton
                                        buttonText={t("button.pos_auto_search")}
                                        onClick={fetchCoordinates}
                                    />
                                    <ReloadButton
                                        buttonText={t("button.pos_reload")}
                                        onClick={reloadMap}
                                    />
                                </form>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <EditButtonLarge
                                buttonText={t("button.pos_edit")}
                                onClick={() => {
                                    setEditing(true);
                                    setSubmitted(false);
                                }}
                            />
                            {submitted && <div style={{ paddingLeft: "50px", paddingTop: "5px" }}>{t("success.upload")}</div>}

                        </div>
                    )}

                </div>)}
            <div ref={mapBox} style={isMobile ? mobileMapStyle : mapStyle} />
        </div>
    );

};
