import { useTranslation } from "react-i18next";
import { MultiSelect } from "@mantine/core";
import { CancelButton, SaveButton } from "../buttons/Buttons";
import ImageContent from "../dynamic/ImageContent";
import useAuth from "../../hooks/useAuth";

const SuggestionEdit = ({ suggestion, setSuggestion, handleCancelClick, handleSaveClick }) => {
    const { t } = useTranslation();
    const { username } = useAuth();
    return (
        <form className="review">
            <div className="form-row">
                <label htmlFor="title">{t("suggestions_page.add-image")}</label>
                <ImageContent
                    username={username}
                    title={"suggestions_" + suggestion?.id}
                    type="image-suggestions-edit"
                    smallEditButton={true}
                />
            </div>
            <div className="form-row">
                <label htmlFor="title">{t("suggestions_page.title")}</label>
                <input
                    type="text"
                    id="title"
                    value={suggestion?.title ? suggestion?.title : ""}
                    className='review'
                    onChange={(event) => {
                        setSuggestion(suggestion => ({
                            ...suggestion,
                            title: event.target.value
                        }))
                    }}
                />
            </div>
            <div className="form-row">
                <label htmlFor="intro">{t("suggestions_page.description")}</label>
                <textarea
                    id="intro"
                    value={suggestion?.intro ? suggestion?.intro : ""}
                    className="review"
                    onChange={(event) => {
                        setSuggestion(suggestion => ({
                            ...suggestion,
                            intro: event.target.value
                        }))
                    }}
                ></textarea>
            </div>
            <div className="form-row">
                <label htmlFor="address">{t("suggestions_page.address")}</label>
                <input
                    type="text"
                    id="address"
                    value={suggestion?.address ? suggestion?.address : ""}
                    style={{ width: "50%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc", backgroundColor: "#f5f5f5", color: "#333" }}
                    onChange={(event) => {
                        setSuggestion(suggestion => ({
                            ...suggestion,
                            address: event.target.value
                        }))
                    }}
                />
            </div>
            <div className="form-row">
                <label htmlFor="intro">{t("suggestions_page.tel")}</label>
                <input
                    type="text"
                    id="tel"
                    value={suggestion?.tel ? suggestion?.tel : ""}
                    className="review"
                    onChange={(event) => {
                        setSuggestion(suggestion => ({
                            ...suggestion,
                            tel: event.target.value
                        }))
                    }}
                ></input>
            </div>
            <div className="form-row">
                <label htmlFor="weblink">{t("suggestions_page.link")}</label>
                <input
                    type="url"
                    id="weblink"
                    value={suggestion?.weblink ? suggestion?.weblink : ""}
                    className='review'
                    onChange={(event) => {
                        if (event.target.value.startsWith("www")) {
                            setSuggestion(suggestion => ({
                                ...suggestion,
                                weblink: "https://" + event.target.value
                            }))
                        } else {
                            setSuggestion(suggestion => ({
                                ...suggestion,
                                weblink: event.target.value
                            }))
                        }
                    }}
                />
            </div>
            <div className="form-row" style={{ paddingBottom: "50px" }}>
                <label htmlFor="tags">{t("suggestions_page.tags")}</label>
                <MultiSelect
                    placeholder={t("suggestions_page.tag-placeholder")}
                    data={t("suggestions_page.tag-options", { returnObjects: true })}
                    value={suggestion?.tags ? suggestion?.tags : []}
                    onChange={(event) => {
                        setSuggestion(suggestion => ({
                            ...suggestion,
                            tags: event
                        }))
                    }}
                    clearable
                    searchable
                />
            </div>
            <div className='edit-container' style={{ marginTop: "-40px", paddingLeft: "15px" }}>
                <SaveButton
                    buttonText={t("button.save")}
                    onClick={(e) => handleSaveClick(e)}
                    type="submit"

                />
                <CancelButton
                    buttonText={t("button.cancel")}
                    onClick={handleCancelClick}
                    type="button"

                />
            </div>
        </form>
    )
};

export default SuggestionEdit;