import React, { lazy, Suspense } from "react";
import './contact.css'
import Map from "../dynamic/Map";
import { SimpleGrid } from '@mantine/core';
import FetchContent from '../dynamic/fetchContent';
import ContactStripe from './ContactInfoForm';
import useAuth from '../../hooks/useAuth.js';
import { useTranslation } from "react-i18next";
import Content from "../dynamic/Content";
import ImageContent from "../dynamic/ImageContent";

const FetchImage = lazy(() => import('../dynamic/fetchImage'))

export default function Contact() {

  const { auth, username } = useAuth();
  const { t } = useTranslation();

  return (
    <div className='default'>
      <div className="stripe">
        <div className="contact-title"> <h1>
          {t("contact_page.contact")}
        </h1> </div>
        <div className="container">
          <hr></hr>
        </div>

        <SimpleGrid cols={2} spacing="lg" breakpoints={[
          { maxWidth: 1000, cols: 1, spacing: 'sm' },
        ]}>

          <div className="container-contact-profile">
            <Suspense fallback={<h1>Loading</h1>}>
              {(!auth?.role ?
                <FetchImage title="contact" type="image-contact" />
                :
                <ImageContent title="contact" username={username} type="image-contact" />
              )}
            </Suspense>
          </div>

          <div className="p container">
            {(!auth?.role ?
              <FetchContent title="p-contact" />
              :
              <Content title={"p-contact"} styling="p" />
            )}
          </div>

        </SimpleGrid>
      </div>

      <div className="stripe stripe-contact">
        <div className="container-contact">
          <ContactStripe />
        </div>
      </div>
      <div>
        {(!auth?.role ?
          <Map /> :
          <Map adminUser={true} />)}
      </div>
    </div>
  );
}
