import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import { defaultLanguage, languageOptions } from '../../constants';
import {
    contactDefaultDisplay, contactDefaultEntries
} from '../../languages/defaultEntries'
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { SaveButton } from '../buttons/Buttons';

const WEBSITE_URL = '/contact';


export default function ContactInfoForm() {
    const art = defaultLanguage;
    const title = "contact";
    const { username, } = useAuth();
    const { t } = useTranslation();

    const defaultEntries = contactDefaultEntries;
    const defaultDisplay = contactDefaultDisplay;

    // const [loading, setLoading] = useState(true);
    const [isContentEmpty, setIsContentEmpty] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [canSave, setCanSave] = useState(false);

    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState(1);
    const [zip, setZip] = useState(1);
    const [postBus, setPostBus] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [tel, setTel] = useState('');
    const [telDisplayed, setTelDisplayed] = useState(false);
    const [mail, setMail] = useState('');
    const [mailError, setMailError] = useState(true);
    const [btw, setBtw] = useState('');
    const [languages, setLanguages] = useState([]);
    const [selectedLanguageOptions, setSelectedLanguageOptions] = useState([]);
    const [tourismLicense, setTourismLicense] = useState('');

    useEffect(() => {
        const getContent = async () => {
            try {
                const response = await axios.get(`${WEBSITE_URL}`, {
                    params: { username, art, title },
                });
                if (response.data.entry) {
                    setName(response.data.entry.name);
                    setLastname(response.data.entry.lastname);
                    setStreet(response.data.entry.street);
                    setNumber(response.data.entry.number);
                    setZip(response.data.entry.zip);
                    setCity(response.data.entry.city);
                    setCountry(response.data.entry.country);
                    setTel(response.data.entry.tel);
                    setTelDisplayed(response.data.entry.telDisplayed)
                    setMail(response.data.entry.mail);
                    setPostBus(response.data.entry.postBus);
                    setBtw(response.data.entry.btw);
                    setLanguages(response.data.entry.languages);
                    setSelectedLanguageOptions(response.data.entry.languages.split(","));
                    setTourismLicense(response.data.entry.tourismLicense);
                }
                // setLoading(false);

            } catch (err) {
                console.log(err);
            }
        };
        getContent();
    }, [username, art, title]);

    const saveContent = async () => {
        try {
            await axios.put(WEBSITE_URL, {
                username,
                art,
                title,
                name,
                lastname,
                street,
                number,
                zip,
                city,
                country,
                tel,
                telDisplayed,
                mail,
                postBus,
                btw,
                languages,
                tourismLicense,
            });
            setIsSubmitted(true);
            setIsContentEmpty(false);
            window.location.reload();
        } catch (err) {
            console.log(err);
        }
    };

    const handleCheckboxChange = (option) => {
        setSelectedLanguageOptions((prevSelectedLanguageOptions) => {
            if (prevSelectedLanguageOptions.includes(option)) {
                return prevSelectedLanguageOptions.filter((selectedOption) => selectedOption !== option);
            } else {
                return [...prevSelectedLanguageOptions, option];
            }
        });
    };

    useEffect(() => {
        setLanguages(selectedLanguageOptions.join(","))
    }, [selectedLanguageOptions])

    const handleSaveClick = (event) => {
        event.preventDefault();
        if (isContentEmpty) {
            alert('Error: Text cannot be empty!');
            return;
        }
        // Pass the updated reviews array to saveContent function
        saveContent();
    };

    const handleMailChange = (event) => {
        setMail(event.target.value);
    };

    useEffect(() => {
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail) && mail !== "") {
            setMailError(false)
        } else {
            setMailError(true)
        }
    }, [mail, mailError])

    useEffect(() => {
        if (languages) {
            if (!mailError && name && lastname && street && number && city && zip && country) {
                setCanSave(true);
            } else {
                setCanSave(false);
            }
        }
    }, [mailError, name, lastname, street, number, city, zip, country, languages])

    return (
        <div>
            <form className="review-form">
                <h3> {t("dashboard_page.contact_form.title")}</h3>
                <div className="form-row">
                    <label className="form-label" htmlFor="name">{t("dashboard_page.contact_form.firstname")}</label>
                    <input
                        type="text"
                        id="name"
                        value={name === defaultEntries.name ? defaultDisplay.name : name}
                        placeholder={name === defaultEntries.name ? defaultDisplay.name : name}
                        onChange={(event) => setName(event.target.value)}
                        className={name ? "valid" : "invalid"}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="lastname">{t("dashboard_page.contact_form.lastname")}</label>
                    <input
                        type="text"
                        id="lastname"
                        value={lastname === defaultEntries.lastname ? defaultDisplay.lastname : lastname}
                        placeholder={lastname === defaultEntries.lastname ? defaultDisplay.lastname : lastname}
                        className={lastname ? "valid" : "invalid"}
                        onChange={(event) => setLastname(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="street address">{t("dashboard_page.contact_form.street")}</label>
                    <input
                        type="text"
                        id="street address"
                        value={street === defaultEntries.street ? defaultDisplay.street : street}
                        placeholder={street === defaultEntries.street ? defaultDisplay.street : street}
                        className={street ? "valid" : "invalid"}
                        onChange={(event) => setStreet(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="number">{t("dashboard_page.contact_form.number")}</label>
                    <input
                        type="number"
                        id="number"
                        value={number === defaultEntries.number ? defaultDisplay.number : number}
                        placeholder={number === defaultEntries.number ? defaultDisplay.number : number}
                        className={number ? "valid" : "invalid"}
                        onChange={(event) => setNumber(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="Postbus">Postbus: </label>
                    <input
                        type="text"
                        id="postBus"
                        value={postBus === defaultEntries.postBus ? defaultDisplay.postBus : postBus}
                        placeholder={postBus === defaultEntries.postBus ? defaultDisplay.postBus : postBus}
                        onChange={(event) => setPostBus(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="city">{t("dashboard_page.contact_form.city")}</label>
                    <input
                        type="text"
                        id="city"
                        value={city === defaultEntries.city ? defaultDisplay.city : city}
                        placeholder={city === defaultEntries.city ? defaultDisplay.city : city}
                        className={city ? "valid" : "invalid"}
                        onChange={(event) => setCity(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="street address">{t("dashboard_page.contact_form.zip")}</label>
                    <input
                        type="number"
                        id="zip code"
                        value={zip === defaultEntries.zip ? defaultDisplay.zip : zip}
                        placeholder={zip === defaultEntries.zip ? defaultDisplay.zip : zip}
                        className={zip ? "valid" : "invalid"}
                        onChange={(event) => setZip(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="country">{t("dashboard_page.contact_form.country")}</label>
                    <input
                        type="text"
                        id="country"
                        value={country === defaultEntries.country ? defaultDisplay.country : country}
                        placeholder={country === defaultEntries.country ? defaultDisplay.country : country}
                        className={country ? "valid" : "invalid"}
                        onChange={(event) => setCountry(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="guests">{t("dashboard_page.contact_form.displayTel")}</label>
                    <input
                        type="checkbox"
                        id="telDisplayed"
                        checked={telDisplayed}
                        style={{ width: "5%", padding: "", borderRadius: "5px", border: "1px solid #ccc", backgroundColor: "#f5f5f5", color: "#333", marginRight: "2px" }}
                        onChange={(event) => setTelDisplayed(event.target.checked)}
                    />
                    {telDisplayed && (
                        <input
                            type="text"
                            id="tel"
                            value={tel === defaultEntries.tel ? defaultDisplay.tel : tel}
                            placeholder={tel === defaultEntries.tel ? defaultDisplay.tel : tel}
                            style={{ width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc", backgroundColor: "#f5f5f5", color: "#333" }}
                            onChange={(event) => setTel(event.target.value)}
                        />
                    )}
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="country">{t("dashboard_page.contact_form.mail")}</label>
                    <input
                        type="text"
                        id="tel"
                        value={mail === defaultEntries.mail ? defaultDisplay.mail : mail}
                        placeholder={mail === defaultEntries.mail ? defaultDisplay.mail : mail}
                        onChange={handleMailChange}
                        className={mailError ? "invalid" : "valid"}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="btw">{t("contact_page.vat")} </label>
                    <input
                        type="text"
                        id="btw"
                        value={btw === defaultEntries.btw ? defaultDisplay.btw : btw}
                        placeholder={btw === defaultEntries.btw ? defaultDisplay.btw : btw}
                        className="valid"
                        onChange={(event) => setBtw(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="tourismLicense">{t("contact_page.tourism")} </label>
                    <input
                        type="text"
                        id="tourismLicense"
                        value={tourismLicense === defaultEntries.tourismLicense ? defaultDisplay.tourismLicense : tourismLicense}
                        placeholder={tourismLicense === defaultEntries.tourismLicense ? defaultDisplay.tourismLicense : tourismLicense}
                        className="valid"
                        onChange={(event) => setTourismLicense(event.target.value)}
                    />
                </div>
                <div className='form-row' style={{ paddingTop: "5px" }}>
                    <label className="form-label" htmlFor="languages">{t("dashboard_page.contact_form.languages")}</label>
                    {languageOptions.map((option) => (
                        <div
                            style={{ fontSize: "16px" }}
                            key={option}>
                            <label>
                                <input
                                    type="checkbox"
                                    style={{ margin: "5px" }}
                                    checked={selectedLanguageOptions.includes(option)}
                                    onChange={() => handleCheckboxChange(option)}
                                />
                                {option}
                            </label>
                        </div>
                    ))}
                </div>
                <SaveButton
                    buttonText={t("button.save")}
                    onClick={handleSaveClick}
                    enabled={canSave}
                />
                {isSubmitted && <p>{t("dashboard_page.contact_form.successmsg")}</p>}
                {!canSave && <p className='error-msg'>{t("errors.fieldRequired")}</p>}
                {mailError && <p className='error-msg'>{t("errors.email")}</p>}
                {(!name || !lastname) && <p className='error-msg'>{t("errors.name")}</p>}
                {(!street || !number || !city || !zip || !country) && <p className='error-msg'>{t("errors.address")}</p>}
                {(languages === null) && <p className='error-msg'>{t("errors.languages")}</p>}
            </form>
        </div>
    );
}
