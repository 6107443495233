import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import { MdLanguage } from 'react-icons/md';
import axios from "../../api/axios";

const LANG_URL = '/contact';

const LanguageDropDown = ({ art, setArt }) => {

    const { i18n } = useTranslation();
    const { username } = useAuth();

    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const [languages, setLanguages] = useState([]);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    }

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        setArt(lang)
        setSelectedLanguage(lang);
        toggleDropdown();
        i18n.changeLanguage(lang);
    };

    useEffect(() => {
        const getContent = async () => {
            try {
                const response = await axios.get(`${LANG_URL}`, {
                    params: { username, art: "nl", title: "contact" },
                });
                if (response.data.entry) {
                    setLanguages(response.data.entry.languages.split(","));
                }
                // setLoading(false);

            } catch (err) {
                console.log(err);
            }
        };
        getContent();
    }, [username]);

    return (
        <div >
            {showDropdown ? (
                <div className='lng-dropdown'>
                    <button
                        className="language-dropdown"
                        onClick={toggleDropdown}
                        type="button"
                    >
                        <span className='save-cancel'>
                            <MdLanguage />
                        </span> {art ? art : selectedLanguage} &#9660;
                    </button>
                    <div style={{ backgroundColor: "white" }}>
                        {languages.map((code) => (
                            <button
                                key={code}
                                value={code}
                                className='drop'
                                type="button"
                                onClick={(e) => {
                                    handleLanguageChange(e.target.value)
                                }}>
                                {code}
                            </button>
                        ))}
                    </div>
                </div>
            ) : (
                <div className='lng-dropdown' >
                    <button
                        className="language-dropdown"
                        onClick={toggleDropdown}
                        type="button">
                        <span className='save-cancel'>
                            <MdLanguage />
                        </span> {art ? art : selectedLanguage} &#9660;
                    </button>
                </div>
            )}
        </div>
    )
}

export default LanguageDropDown;