// Layout.js
import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import React from 'react';
import Nav from './components/navBar/Nav';
import EnFooter from './components/footer/Footer';

const Layout = () => {
    const [outletRendered, setOutletRendered] = useState(false);

    useEffect(() => {
        setOutletRendered(true);
    }, []);

    return (
        <main>
            <Nav />
            <Outlet />
            {outletRendered && <EnFooter />}
        </main>
    );
}

export default Layout;