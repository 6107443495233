import './footer.css';
import React, { useState, useEffect } from 'react';
import Long_logo_light from '../../assets/Long_logo_light@4x.png';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from "../../api/axios";
import useLogout from '../../hooks/useLogout';
import { LoginButton, LogoutButton } from '../buttons/Buttons';

const WEBSITE_URL = '/pdf_url';

const year = new Date();

// Adapt date automatically
function formatDate(date) {
  return new Intl.DateTimeFormat(
    'en-US',
    { year: "numeric" }
  ).format(date);
}

export default function Footer() {
  const { auth, username } = useAuth();
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const title = "TandC";
  const logout = useLogout();

  const [TandCUrl, setTandCUrl] = useState(null);

  const signIn = async () => {
    navigate('/login/');
  }

  const signOut = async () => {
    await logout();
    navigate('/');
  }

  useEffect(() => {
    async function fetch() {
      await axios.get(`${WEBSITE_URL}`, {
        params: { username, title },
      }).then((res) => {
        setTandCUrl(res.data)
      }).catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
    }
    fetch()
  }, [username])

  return (
    <div className="container-footer">
      <div className="column">
        {!auth?.role ? (
          <div>
            <LoginButton
              buttonText={t("footer.login")}
              onClick={signIn}
              href={"/login"}
            />
          </div>
        ) : (
          <div>
            <LogoutButton
              buttonText={t("footer.logout")}
              onClick={signOut}
              href={"/home"}
            />
          </div>
        )}
      </div>
      <div className="column">
        <div className="terms">
          {TandCUrl && <a className='suggestions-text' href={TandCUrl}>{t("footer.TC")}</a>}
          {(() => {
            switch (i18n.language) {
              case "de":
                return (
                  <>
                    <p href="https://resirent.org/products/resirent-website">
                      &copy; {formatDate(year)}
                    </p>
                    Made by {i18n.language}
                    <a className="logo" href="https://resirent.org/products/resirent-website">
                      <img src={Long_logo_light} alt="ResiRent logo" />
                    </a>
                  </>
                );
              case "nl":
                return (
                  <>
                    <p href="https://resirent.org/nl/producten/resirent-website">
                      &copy; {formatDate(year)}
                    </p>
                    Made by
                    <a className="logo" href="https://resirent.org/nl/producten/resirent-website">
                      <img src={Long_logo_light} alt="ResiRent logo" />
                    </a>
                  </>
                );
              // Add more cases for other languages if needed
              default:
                return (
                  <>
                    <p href="https://resirent.org/de/produkte/resirent-website">
                      &copy; {formatDate(year)}
                    </p>
                    Made by
                    <a className="logo" href="https://resirent.org/de/produkte/resirent-website">
                      <img src={Long_logo_light} alt="ResiRent logo" />
                    </a>
                  </>
                );
            }
          })()}
        </div>
      </div>
      <div className="column">
        <img
          className="footer-greencheck"
          src="https://api.thegreenwebfoundation.org/greencheckimage/resirent.org?nocache=true"
          alt="This website is hosted Green - checked by thegreenwebfoundation.org"
        />
      </div>
    </div>

  )
}