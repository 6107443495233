import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import { defaultLanguage } from '../../constants';
import {
    accommodationFormDefaultDisplay, accFormDefaultEntries,
} from '../../languages/defaultEntries';
import CurrencyDropdown from './CurrencyDropdown';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { SaveButton } from '../buttons/Buttons';

const WEBSITE_URL = '/dashboard';

export default function AccommodationList() {
    const art = defaultLanguage;
    const title = "accomodationForm";
    const { username } = useAuth();
    const { t } = useTranslation();
    const [canSave, setCanSave] = useState(false);

    const defaultDisplay = accommodationFormDefaultDisplay;
    const defaultEntry = accFormDefaultEntries;

    // const [loading, setLoading] = useState(true);
    const [isContentEmpty, setIsContentEmpty] = useState(false);

    const [accomodationType, setAccomodationType] = useState('-1');
    const [guests, setGuests] = useState(1);
    const [bedrooms, setBedrooms] = useState(1);
    const [bathrooms, setBathrooms] = useState(1);
    const [dogs, setDogs] = useState(false);
    const [wheelchair, setWheelchair] = useState(false);
    const [parking, setParking] = useState(false);
    const [childrenBed, setChildrenBed] = useState(false);
    const [selfCheckIn, setSelfCheckIn] = useState(false);
    const [numNights, setnumNights] = useState(1);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [singleBeds, setSingleBeds] = useState(2);
    const [doubleBeds, setDoubleBeds] = useState(2);
    const [sofaBeds, setSofaBeds] = useState(2);
    const [checkIn, setCheckIn] = useState(-1);
    const [checkOut, setCheckOut] = useState(-1);
    const [wifi, setWifi] = useState(true);
    const [nameAccommodation, setNameAccommodation] = useState('');
    const [lowerPrice, setLowerPrice] = useState(0);
    const [higherPrice, setHigherPrice] = useState(0);
    const [cleaningIncluded, setCleaningIncluded] = useState(false);
    const [cityTaxIncluded, setcityTaxIncluded] = useState(false);
    const [cleaningService, setCleaningService] = useState("0");
    const [luggageService, setLuggageService] = useState("0");
    const [breakfastService, setBreakfastService] = useState("0");
    const [longStay, setLongStay] = useState("0");
    const [currency, setCurrency] = useState("");
    const [meterSquared, setMeterSquared] = useState(-1);
    const [tourismOfficeLink, setTourismOfficeLink] = useState("");

    useEffect(() => {
        if (accomodationType !== "-1" && nameAccommodation && guests && bedrooms) {
            setCanSave(true);
        }
    }, [accomodationType, nameAccommodation, guests, bedrooms])

    useEffect(() => {
        const getContent = async () => {
            try {
                const response = await axios.get(`${WEBSITE_URL}`, {
                    params: { username, art, title },
                });
                if (response.data.entry) {
                    setAccomodationType(response.data.entry.accomodationType);
                    setGuests(response.data.entry.guests);
                    setBedrooms(response.data.entry.bedrooms);
                    setBathrooms(response.data.entry.bathrooms);
                    setDogs(response.data.entry.dogs);
                    setWheelchair(response.data.entry.wheelchair);
                    setParking(response.data.entry.parking);
                    setChildrenBed(response.data.entry.childrenBed)
                    setSelfCheckIn(response.data.entry.selfCheckIn);
                    setnumNights(response.data.entry.numNights);
                    setSingleBeds(response.data.entry.singleBeds);
                    setDoubleBeds(response.data.entry.doubleBeds);
                    setSofaBeds(response.data.entry.sofaBeds);
                    setCheckIn(response.data.entry.checkIn);
                    setCheckOut(response.data.entry.checkOut);
                    setWifi(response.data.entry.wifi);
                    setNameAccommodation(response.data.entry.nameAccommodation);
                    setLowerPrice(response.data.entry.lowerPrice);
                    setHigherPrice(response.data.entry.higherPrice);
                    setCleaningIncluded(response.data.entry.cleaningIncluded);
                    setcityTaxIncluded(response.data.entry.cityTaxIncluded);
                    setCleaningService(response.data.entry.cleaningService);
                    setBreakfastService(response.data.entry.breakfastService);
                    setLuggageService(response.data.entry.luggageService);
                    setLongStay(response.data.entry.longStay);
                    setCurrency(response.data.entry.currency);
                    setMeterSquared(response.data.entry.meterSquared);
                    setTourismOfficeLink(response.data.entry.tourismOfficeLink);
                }
                // setLoading(false);
            } catch (err) {
                console.log(err);
            }
        };
        getContent();
    }, [username, art, title]);

    const saveContent = async () => {
        try {
            await axios.put(WEBSITE_URL, {
                username,
                art,
                title,
                accomodationType,
                guests,
                bedrooms,
                bathrooms,
                dogs,
                wheelchair,
                parking,
                childrenBed,
                selfCheckIn,
                numNights,
                singleBeds,
                doubleBeds,
                sofaBeds,
                checkIn,
                checkOut,
                wifi,
                nameAccommodation,
                lowerPrice,
                higherPrice,
                cityTaxIncluded,
                cleaningIncluded,
                cleaningService,
                luggageService,
                breakfastService,
                longStay,
                currency,
                meterSquared,
                tourismOfficeLink,
            });
            setIsSubmitted(true);
            setIsContentEmpty(false);
        } catch (err) {
            console.log(err);
        }
    };

    const handleSaveClick = (event) => {
        event.preventDefault();
        if (isContentEmpty) {
            alert('Error: Text cannot be empty!');
            return;
        }
        // Pass the updated reviews array to saveContent function
        saveContent();
    };

    /*     const numberInputFields1 = {
            "guests": setGuests,
            "bedrooms": setBedrooms,
            "singleBeds": setSingleBeds,
            "doubleBeds": setDoubleBeds,
            "sofaBeds": setSofaBeds,
            "bathrooms": setBathrooms,
            "numNights": setnumNights
        }
    
        const numberInputFields2 = {
            "lowerPrice": setLowerPrice,
            "higherPrice": setHigherPrice,
        }
    
        const checkBoxFields1 = {
            "dogs": setDogs,
            "wheelchair": setWheelchair,
            "parking": setParking,
            "childrenBed": setChildrenBed,
            "selfCheckIn": setSelfCheckIn,
            "wifi": setWifi
        };
    
        const checkBoxFields2 = {
            "cleaningIncluded": setCleaningIncluded,
            "cityTaxIncluded": setcityTaxIncluded,
        }
    
        const CheckBoxFieldsMap = (obj) => {
            return (Object.keys(obj).map((key) => {
                return (
                    <div className="form-row">
                        <label className="form-label" htmlFor={key}>
                            {accommodationForm.key}
                            <input
                                type="checkbox" d
                                id={key}
                                value={key}
                                checked={key}
                                style={{ marginLeft: "10px" }}
                                onChange={(event) => obj[key](event.target.checked)}
                            />
                        </label>
                    </div>
                )
            }))
        } */

    return (
        <div>
            <form className="review-form">

                <h3> {t("dashboard_page.acc_form.title")} </h3>
                <div className="form-row">
                    <label className="form-label" htmlFor="nameAccommodation">{t("dashboard_page.acc_form.nameAccommodation")} </label>
                    <input
                        type="text"
                        id="nameAccommodation"
                        value={nameAccommodation === defaultEntry.nameAccommodation ? defaultDisplay.nameAccommodation : nameAccommodation}
                        placeholder={nameAccommodation === defaultEntry.nameAccommodation ? defaultDisplay.nameAccommodation : nameAccommodation}
                        className={nameAccommodation ? "valid" : "invalid"}
                        onChange={(event) => setNameAccommodation(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="type">{t("dashboard_page.acc_form.type")}</label>
                    <select
                        id="type"
                        value={t("codes.acc_types." + accomodationType) === defaultEntry[t("codes.acc_types." + accomodationType)] ? defaultDisplay.t("codes.acc_types." + accomodationType) : t("codes.acc_types." + accomodationType)}
                        onChange={(event) => setAccomodationType(t("rev_codes.acc_types." + event.target.value))}
                        style={accomodationType !== "-1" ? { width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc" } : { width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid red" }}
                    >
                        <option value={t("codes.acc_types." + accomodationType) === defaultEntry[t("codes.acc_types." + accomodationType)] ? defaultDisplay.t("codes.acc_types." + accomodationType) : t("codes.acc_types." + accomodationType)}>{t("codes.acc_types." + accomodationType) === defaultEntry[t("codes.acc_types." + accomodationType)] ? defaultDisplay.t("codes.acc_types." + accomodationType) : t("codes.acc_types." + accomodationType)}</option>
                        {t("dashboard_page.acc_types", { returnObjects: true }).map((type) => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="guests">{t("dashboard_page.acc_form.guests")}</label>
                    <input
                        type="number"
                        id="guests"
                        value={guests === defaultEntry.guests ? defaultDisplay.guests : guests}
                        style={{ width: "75px" }}
                        className={guests !== -1 ? "valid" : "invalid"}
                        onChange={(event) => setGuests(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="bedrooms">{t("dashboard_page.acc_form.bedrooms")}</label>
                    <input
                        type="number"
                        id="bedrooms"
                        value={bedrooms === defaultEntry.bedrooms ? defaultDisplay.bedrooms : bedrooms}
                        style={{ width: "75px" }}
                        className={bedrooms !== -1 ? "valid" : "invalid"}
                        onChange={(event) => setBedrooms(event.target.value)}
                    />

                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="singleBeds">{t("dashboard_page.acc_form.singleBeds")}</label>
                    <input
                        type="number"
                        id="singleBeds"
                        value={singleBeds === defaultEntry.singleBeds ? defaultDisplay.singleBeds : singleBeds}
                        style={{ width: "75px", marginRight: "10px" }}
                        className="form-input"
                        onChange={(event) => setSingleBeds(event.target.value)}
                    />
                    <label className="form-label" htmlFor="doubleBeds">{t("dashboard_page.acc_form.doubleBeds")}</label>
                    <input
                        type="number"
                        id="doubleBeds"
                        style={{ width: "75px", marginRight: "10px" }}
                        value={doubleBeds === defaultEntry.doubleBeds ? defaultDisplay.doubleBeds : doubleBeds}
                        className="form-input"
                        onChange={(event) => setDoubleBeds(event.target.value)}
                    />
                    <label className="form-label" htmlFor="sofaBeds">{t("dashboard_page.acc_form.sofaBeds")}</label>
                    <input
                        type="number"
                        id="sofaBeds"
                        value={sofaBeds === defaultEntry.sofaBeds ? defaultDisplay.sofaBeds : sofaBeds}
                        style={{ width: "75px" }}
                        className="form-input"
                        onChange={(event) => setSofaBeds(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="bathrooms">{t("dashboard_page.acc_form.bathrooms")}</label>
                    <input
                        type="number"
                        id="bathroom"
                        value={bathrooms === defaultEntry.bathrooms ? defaultDisplay.bathrooms : bathrooms}
                        className={bathrooms ? "valid" : "invalid"}
                        style={{ width: "75px" }}
                        onChange={(event) => setBathrooms(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="numNights">{t("dashboard_page.acc_form.numNights")}</label>
                    <input
                        type="number"
                        id="numNights"
                        value={numNights === defaultEntry.numNights ? defaultDisplay.numNights : numNights}
                        style={{ width: "75px" }}
                        onChange={(event) => setnumNights(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="dogs" style={{ display: "flex", alignItems: "center" }}>
                        {t("dashboard_page.acc_form.dogs")}
                        <input
                            type="checkbox"
                            id="dogs"
                            checked={dogs}
                            value={dogs}
                            style={{ marginLeft: "10px", width: "auto" }}
                            onChange={(event) => setDogs(event.target.checked)}
                        />
                    </label>
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="wheelchair" style={{ display: "flex", alignItems: "center" }}>
                        {t("dashboard_page.acc_form.wheelchair")}
                        <input
                            type="checkbox"
                            id="wheelchair"
                            checked={wheelchair}
                            value={wheelchair}
                            style={{ marginLeft: "10px", width: "auto" }}
                            onChange={(event) => setWheelchair(event.target.checked)}
                        />
                    </label>
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="parking" style={{ display: "flex", alignItems: "center" }}>
                        {t("dashboard_page.acc_form.parking")}
                        <input
                            type="checkbox"
                            id="parking"
                            value={parking}
                            checked={parking}
                            style={{ marginLeft: "10px", width: "auto" }}
                            onChange={(event) => setParking(event.target.checked)}
                        />
                    </label>
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="childrenBed" style={{ display: "flex", alignItems: "center" }}>
                        {t("dashboard_page.acc_form.childrenBed")}
                        <input
                            type="checkbox"
                            id="childrenBed"
                            value={childrenBed}
                            checked={childrenBed}
                            style={{ marginLeft: "10px", width: "auto" }}
                            onChange={(event) => setChildrenBed(event.target.checked)}
                        />
                    </label>
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="selfCheckIn">
                        {t("dashboard_page.acc_form.selfCheckIn")}
                        <input
                            type="checkbox"
                            id="selfCheckIn"
                            value={selfCheckIn}
                            checked={selfCheckIn}
                            style={{ marginLeft: "10px" }}
                            onChange={(event) => setSelfCheckIn(event.target.checked)}
                        />
                    </label>
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="wifi">
                        {t("dashboard_page.acc_form.wifi")}
                        <input
                            type="checkbox"
                            id="wifi"
                            value={wifi}
                            checked={wifi}
                            style={{ marginLeft: "10px" }}
                            onChange={(event) => setWifi(event.target.checked)}
                        />
                    </label>
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="checkIn" style={{ display: "flex", alignItems: "center" }}>
                        {t("dashboard_page.acc_form.checkIn")}
                        <input
                            type="time"
                            id="checkIn"
                            value={checkIn === defaultEntry.checkIn ? defaultDisplay.checkIn : checkIn}
                            style={{ marginLeft: "10px", width: "auto" }}
                            onChange={(event) => setCheckIn(event.target.value)}
                        />
                    </label>
                    <label className="form-label" htmlFor="checkOut" style={{ display: "flex", alignItems: "center" }}>
                        {t("dashboard_page.acc_form.checkOut")}
                        <input
                            type="time"
                            id="checkOut"
                            value={checkOut === defaultEntry.checkOut ? defaultDisplay.checkOut : checkOut}
                            style={{ marginLeft: "10px", width: "auto" }}
                            onChange={(event) => setCheckOut(event.target.value)}
                        />
                    </label>
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="cleaningIncluded">
                        {t("dashboard_page.acc_form.cleaningIncluded")}
                        <input
                            type="checkbox"
                            id="cleaningIncluded"
                            value={cleaningIncluded}
                            checked={cleaningIncluded}
                            style={{ marginLeft: "10px" }}
                            onChange={(event) => setCleaningIncluded(event.target.checked)}
                        />
                    </label>
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="cityTaxIncluded">
                        {t("dashboard_page.acc_form.cityTaxIncluded")}
                        <input
                            type="checkbox"
                            id="cityTaxIncluded"
                            value={cityTaxIncluded}
                            checked={cityTaxIncluded}
                            style={{ marginLeft: "10px" }}
                            onChange={(event) => setcityTaxIncluded(event.target.checked)}
                        />
                    </label>
                </div>
                <div className = "form-row">
                    <label className="form-label" htmlFor="tourismOfficeLink">{t("dashboard_page.acc_form.tourismOfficeLink")}   </label>
                    <input
                        type="text"
                        style={{ width: "300px", marginRight: "10px" }}
                        id="tourismOfficeLink"
                        value={tourismOfficeLink === defaultEntry.tourismOfficeLink ? defaultDisplay.tourismOfficeLink : tourismOfficeLink}
                        className={tourismOfficeLink ? "valid" : "invalid"}
                        onChange={(event) => setTourismOfficeLink(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="lowerPrice">{t("dashboard_page.acc_form.lowerPrice")}</label>
                    <input
                        type="number"
                        id="lowerPrice"
                        style={{ width: "100px", marginRight: "10px" }}
                        value={lowerPrice === defaultEntry.lowerPrice ? defaultDisplay.lowerPrice : lowerPrice}
                        className={lowerPrice ? "valid" : "invalid"}
                        onChange={(event) => setLowerPrice(event.target.value)}
                    />
                    <label className="form-label" htmlFor="higherPrice">{t("dashboard_page.acc_form.higherPrice")}</label>
                    <input
                        type="number"
                        id="higherPrice"
                        style={{ width: "100px", marginRight: "10px" }}
                        value={higherPrice === defaultEntry.higherPrice ? defaultDisplay.higherPrice : higherPrice}
                        className={higherPrice ? "valid" : "invalid"}
                        onChange={(event) => setHigherPrice(event.target.value)}
                    />
                    <CurrencyDropdown setCurrency={setCurrency} />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="type">{t("dashboard_page.acc_form.cleaningService")}</label>
                    <select
                        id="type"
                        value={t("service_codes." + cleaningService)}
                        onChange={(event) => setCleaningService(t("rev_service_codes." + event.target.value))}
                        style={{ width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc", backgroundColor: "#f5f5f5", color: "#333" }}
                    >
                        <option value={t("service_codes." + cleaningService)}>{t("service_codes." + cleaningService)}</option>
                        {t("dropdown.general", { returnObjects: true }).map((type) => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="type">{t("dashboard_page.acc_form.luggageService")}</label>
                    <select
                        id="type"
                        value={t("service_codes." + luggageService)}
                        onChange={(event) => setLuggageService(t("rev_service_codes." + event.target.value))}
                        style={{ width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc", backgroundColor: "#f5f5f5", color: "#333" }}
                    >
                        <option value={t("service_codes." + luggageService)}>{t("service_codes." + luggageService)}</option>
                        {t("dropdown.general", { returnObjects: true }).map((type) => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="type">{t("dashboard_page.acc_form.breakfast")}</label>
                    <select
                        id="type"
                        value={t("service_codes." + breakfastService)}
                        onChange={(event) => setBreakfastService(t("rev_service_codes." + event.target.value))}
                        style={{ width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc", backgroundColor: "#f5f5f5", color: "#333" }}
                    >
                        <option value={t("service_codes." + breakfastService)}>{t("service_codes." + breakfastService)}</option>
                        {t("dropdown.breakfast", { returnObjects: true }).map((type) => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="type">{t("dashboard_page.acc_form.longStay")}</label>
                    <select
                        id="type"
                        value={t("service_codes." + longStay)}
                        onChange={(event) => setLongStay(t("rev_service_codes." + event.target.value))}
                        style={{ width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc", backgroundColor: "#f5f5f5", color: "#333" }}
                    >
                        <option value={t("service_codes." + longStay)}>{t("service_codes." + longStay)}</option>
                        {t("dropdown.general", { returnObjects: true }).map((type) => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="meterSquared">{t("dashboard_page.acc_form.meter")}</label>
                    <input
                        type="number"
                        id="meterSquared"
                        value={meterSquared === defaultEntry.meterSquared ? defaultDisplay.meterSquared : meterSquared}
                        style={{ width: "75px" }}
                        onChange={(event) => setMeterSquared(event.target.value)}
                    />
                </div>
                <SaveButton
                    buttonText={t("button.save")}
                    onClick={handleSaveClick}
                    enabled={canSave}
                />
                {!canSave && <p className='error-msg'>{t("errors.fieldRequired")}</p>}
                {isSubmitted && <p>{t("dashboard_page.acc_form.successmsg")}</p>}
            </form>
        </div>

    );
}
