import React, { useState } from 'react';
import { AiFillDownCircle, AiFillUpCircle } from 'react-icons/ai';
import useAuth from '../../hooks/useAuth.js';
import { useTranslation } from 'react-i18next';


const ReviewsCarousel = ({ reviews }) => {

    const { t } = useTranslation();
    const { auth } = useAuth();
    const [currentIndex, setCurrentIndex] = useState(0);

    const previousReview = () => {
        const lastIndex = reviews.length - 1;
        const shouldResetIndex = currentIndex === 0;
        const index = shouldResetIndex ? lastIndex : currentIndex - 1;
        setCurrentIndex(index);
    };

    const nextReview = () => {
        const lastIndex = reviews.length - 1;
        const shouldResetIndex = currentIndex === lastIndex;
        const index = shouldResetIndex ? 0 : currentIndex + 1;
        setCurrentIndex(index);
    };

    return (
        <div>
            {(reviews.length === 0 && Boolean(auth?.role)) && <div className='hint-msg'>{t("hints.no_reviews")}</div>}
            {(reviews.length > 0) && <div>
                <div className="reviews-line"></div>
                <div>
                    <span className='arrow-container' onClick={previousReview}>
                        <AiFillUpCircle size='50px' className='arrow-icon' />
                    </span>
                    <div className='review-bg'>
                        <div>
                            {reviews[currentIndex] && <div className="review">
                                <h3>{reviews[currentIndex].title}</h3>
                                <p className="review-txt">{reviews[currentIndex].comment}</p>
                                <p className="review-info">
                                    {t("reviews.reviewed")} <span className="guest-name">{reviews[currentIndex].guestName}</span> {t("reviews.on")}{" "}
                                    <span className="review-date">{reviews[currentIndex].date}</span>
                                </p>
                            </div>}
                        </div>
                    </div>
                    <span className='arrow-container' onClick={nextReview}>
                        <AiFillDownCircle size='50px' className='arrow-icon' />
                    </span>
                </div>
                <div className="reviews-line"></div>
            </div>}
        </div>
    );
};

export default ReviewsCarousel;







