import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import { fonts } from '../../constants/fonts';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import FontsDropdown from './FontsDropdown';
import ColorChooser from './ColorChooser';
import StylePreview from '../dynamic/StylePreview';
import { SaveButton } from '../buttons/Buttons';
const WEBSITE_URL = '/styles';

export default function StylesForm() {
    const { username } = useAuth();
    const { t } = useTranslation();

    const [isContentEmpty, setIsContentEmpty] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [color1, setColor1] = useState("#FFFFFF");
    const [color2, setColor2] = useState("#152A35");
    const [color3, setColor3] = useState("#E5E3D7");
    const [color4, setColor4] = useState("#9B8D58");
    const [fontHeading, setFontHeading] = useState('');
    const [fontText, setFontText] = useState('');


    useEffect(() => {
        const getContent = async () => {
            try {
                const response = await axios.get(`${WEBSITE_URL}`, {
                    params: { username },
                });
                if (response.data.entry) {
                    setColor1(response.data.entry.color1);
                    setColor2(response.data.entry.color2);
                    setColor3(response.data.entry.color3);
                    setColor4(response.data.entry.color4);
                    setFontHeading(response.data.entry.fontHeading);
                    setFontText(response.data.entry.fontText);
                }
            } catch (err) {
                console.log(err);
            }
        };
        getContent();
    }, [username]);

    const saveContent = async () => {
        try {
            await axios.put(WEBSITE_URL, {
                username,
                color1,
                color2,
                color3,
                color4,
                fontHeading,
                fontText,
            });
            setIsSubmitted(true);
            setIsContentEmpty(false);
            window.location.reload(); // Refresh the page
        } catch (err) {
            console.log(err);
        }
    };

    const handleSaveClick = (event) => {
        event.preventDefault();
        if (isContentEmpty) {
            alert('Error: Text cannot be empty!');
            return;
        }
        // Pass the updated reviews array to saveContent function
        saveContent();
    };

    return (
        <div>
            <form className="review-form">
                <h3> {t("dashboard_page.styles_form.title")}</h3>
                <div className="form-row">
                    <label className="form-label" htmlFor="type">{t("dashboard_page.styles_form.colorpalet")} </label>
                    <ColorChooser onSetBackground={setColor1} onSetNavigation={setColor2} onSetAccent={setColor3} onSetButton={setColor4} />
                </div>
                <i className='form-label'>
                    {t("dashboard_page.styles_form.colorSelf")}
                </i>
                <div className="form-row">
                    <label className="form-label" htmlFor="name">{t("dashboard_page.styles_form.background")} </label>
                    <input
                        type="color"
                        id="color1"
                        value={color1}
                        placeholder={color1}
                        onChange={(event) => setColor1(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="name">{t("dashboard_page.styles_form.navbar")} </label>
                    <input
                        type="color"
                        id="color2"
                        value={color2}
                        placeholder={color2}
                        onChange={(event) => setColor2(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="name">{t("dashboard_page.styles_form.accent1")} </label>
                    <input
                        type="color"
                        id="color3"
                        value={color3}
                        placeholder={color3}
                        onChange={(event) => setColor3(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="name">{t("dashboard_page.styles_form.accent2")} </label>
                    <input
                        type="color"
                        id="color1"
                        value={color4}
                        placeholder={color4}
                        onChange={(event) => setColor4(event.target.value)}
                    />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="type">{t("dashboard_page.styles_form.fontTitle")}</label>
                    <FontsDropdown fonts={fonts} onSet={setFontHeading} current={fontHeading} />
                </div>
                <div className="form-row">
                    <label className="form-label" htmlFor="type">{t("dashboard_page.styles_form.fontParagraph")} </label>
                    <FontsDropdown fonts={fonts} onSet={setFontText} current={fontText} />
                </div>
                <StylePreview
                    background={color1}
                    navigation={color2}
                    accent={color3}
                    button={color4}
                    paragraph={fontText}
                    heading={fontHeading}
                    title={t("dashboard_page.styles_form.preview")}
                    sample1={t("dashboard_page.styles_form.sampleText1")}
                    sample2={t("dashboard_page.styles_form.sampleText2")}
                />
                <SaveButton
                    buttonText={t("button.save")}
                    onClick={handleSaveClick}
                />
                {isSubmitted && <p>{t("dashboard_page.contact_form.successmsg")}</p>}
            </form>
        </div>
    );
}
