import { Carousel } from '@mantine/carousel';
import { useCallback, useEffect, useState } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';

const WEBSITE_URL = "/image_url"

export default function FetchCarouselContent(props) {
    const galleryTitle = "gallery-" + props.title;
    const [images] = useState([]);
    const [lastIndex, setLastIndex] = useState(0);
    const [imageObjects, setImageObjects] = useState([]);
    const [status] = useState("");
    const { username } = useAuth();

    const updateGallery = useCallback(async () => {
        const url = WEBSITE_URL + "/gallery";
        try {
            const response = await axios.get(url, {
                params: { title: galleryTitle, username }
            });
            if (response.data) {
                // sort by public id (order added)
                if (!(response.data.length === 0)) {
                    const images = response.data.sort((a, b) => {
                        if (a.public_id < b.public_id) {
                            return -1;
                        }
                        if (a.public_id > b.public_id) {
                            return 1;
                        }
                        return 0;
                        //bring urls in array form
                    }).map((item, idx) => {
                        return (
                            {
                                url: item.url,
                                public_id: item.public_id,
                                idx: idx
                            }
                        )
                    })
                    const lastIndex = Math.max(...images.map(obj => obj.public_id.split('-').slice(-1)));
                    if (lastIndex) {
                        setLastIndex(lastIndex);
                    }
                    setImageObjects(images);
                }
            }
        } catch (err) {
            console.error(err);
        }
    }, [galleryTitle, username])

    useEffect(() => {
        const update = async () => {
            await updateGallery(lastIndex, images, status);
        }
        update();
    }, [lastIndex, images, status, updateGallery]);

    useEffect(() => {
        const update = async () => {
            await updateGallery(lastIndex, images, status);
        }
        update();
    }, [updateGallery, images, lastIndex, status])

    // const handleReload = async () => {
    //     await updateGallery();
    // }

    return (
        <div className="container">
            {(imageObjects.length > 0) && <Carousel
                slideSize="33.3%"
                withIndicators
                slideGap="md"
                breakpoints={[
                    { maxWidth: 'md', slideSize: '50%', slideGap: '5px' },
                    { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
                ]}
                loop
                align="start"
            >
                {imageObjects.map(
                    (image) => <Carousel.Slide key={image.idx}>
                        {
                            <div className='gallery-image-container'>
                                <img className='gallery-image' key={image.idx} src={image.url} alt="gallery" />
                            </div>
                        }
                    </Carousel.Slide>)}
            </Carousel>}
        </div>
    )
}