export const LANGUAGES = [
    { label: "German", code: "de" },
    { label: "English", code: "en" },
    { label: "Dutch", code: "nl" },
];

export const defaultLanguage = "nl";

export const languageOptions = ["en", "nl", "de"]

export const currencies = ["EUR", "CHF"]

