import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import { defaultLanguage } from '../../constants';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faEnvelopeOpenText, faMapLocationDot, faPhone, faListDots } from '@fortawesome/free-solid-svg-icons'
import { contactDefaultEntries } from '../../languages/defaultEntries';

const WEBSITE_URL = '/contact';


function formattel(host) {
    return 'tel:' + host;
}

function formatmail(host) {
    return 'mailto:' + host;
}

function formataddress(street, number, zip, city) {
    return 'https://maps.google.com/?q=' + street + "+" + number + "+" + zip + "+" + city;
}

const ContactStripe = () => {

    const art = defaultLanguage;
    const title = "contact";
    const { username, auth } = useAuth();
    const { t } = useTranslation();

    const [street, setStreet] = useState('');
    const [number, setNumber] = useState(-1);
    const [zip, setZip] = useState(-1);
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [tel, setTel] = useState('');
    const [telDisplayed, setTelDisplayed] = useState(false);
    const [mail, setMail] = useState('');
    const [btw, setBtw] = useState('');
    const [tourismLicense, setTourismLicense] = useState('');

    useEffect(() => {
        const getContent = async () => {
            await axios.get(`${WEBSITE_URL}`, {
                params: { username, art, title },
            }).then((res) => {
                setStreet(res?.data?.entry?.street);
                setNumber(res?.data?.entry?.number);
                setZip(res?.data?.entry?.zip);
                setCity(res?.data?.entry?.city);
                setCountry(res?.data?.entry?.country);
                setTel(res?.data?.entry?.tel);
                setTelDisplayed(res?.data?.entry?.telDisplayed);
                setMail(res?.data?.entry?.mail);
                setBtw(res?.data?.entry?.btw);
                setTourismLicense(res?.data?.entry?.tourismLicense);
            }).catch((err) => {
                console.log(err);
            });
        };
        getContent();
    }, [username, art, title]);

    const validAddress = (street !== contactDefaultEntries.street &&
        number !== contactDefaultEntries.number &&
        zip !== contactDefaultEntries.zip &&
        city !== contactDefaultEntries.city)
    const allEntries = (validAddress && (mail !== contactDefaultEntries.mail) && btw);

    return (
        <div className="dog-friendly-home stripe-carousel p" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '100px' }}>
            {(!allEntries && auth?.role) && <div className='hint-msg'>{t("hints.missing_contact_details")}</div>}
            {(mail !== contactDefaultEntries.mail) && <div style={{ textAlign: 'center' }}>
                <h3><FontAwesomeIcon icon={faEnvelope} /></h3>
                <h3>{t("contact_page.mail")}</h3>
                <a href={formatmail(mail)} className="black-link">{mail}</a>
                <br />
                <br />
            </div>}
            {validAddress && <div style={{ textAlign: 'center' }}>
                <h3><FontAwesomeIcon icon={faMapLocationDot} /></h3>
                <h3>{t("contact_page.address")}</h3>
                <a
                    href={formataddress(street, number, zip, city)}
                    target="_blank"
                    rel='noreferrer'
                    className="black-link"
                >
                    {street} {number}<br />
                    {zip} {city}, {country}<br />
                </a>
            </div>}
            {btw && <div style={{ textAlign: 'center' }}>
                <h3><FontAwesomeIcon icon={faEnvelopeOpenText} /></h3>
                <h3>{t("contact_page.vat")}</h3>
                {btw} <br />
                <br />
            </div>}
            {tourismLicense && <div style={{ textAlign: 'center' }}>
                <h3><FontAwesomeIcon icon={faListDots} /></h3>
                <h3>{t("contact_page.tourism")}</h3>
                {tourismLicense} <br />
                <br />
            </div>}
            {telDisplayed && tel !== contactDefaultEntries.tel ? (
                <div style={{ textAlign: 'center' }}>
                    <h3><FontAwesomeIcon icon={faPhone} /></h3>
                    <h3>{t("contact_page.phone")}</h3>
                    <a href={formattel(tel)} className="black-link">{tel}</a>
                    <br />
                    <br />
                </div>
            ) : (
                <div></div>
            )}
        </div>

    );
};

export default ContactStripe;



