import React, { useState } from 'react';

const FontsDropdown = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(props.current);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='fonts-dropdown-container' style={{ fontFamily: selectedOption }}>
            <div className="dropdown-toggle" onClick={toggleDropdown}>
                {selectedOption ? selectedOption : <>{props.current} </>}
            </div>
            {(isOpen && props.fonts !== null) && (
                <div className='fonts-dropdown' >
                    {props.fonts.map((font) => (
                        <div className='fonts-dropdown-element' style={{ fontFamily: `${font}` }} onClick={() => { setSelectedOption(font); setIsOpen(false); props.onSet(font) }}>{font}</div>
                    ))}
                </div>
            )
            }
        </div>
    );
};

export default FontsDropdown;
