import React from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { viewerDefaultDisplay, editorDefaultEntries } from '../../languages/defaultEntries';

const WEBSITE_URL = '/website';

// Fetch content from the db website
// @param: language, tag, username
// return: content

const FetchContent = (props) => {
    const [content, setContent] = React.useState();
    const { username } = useAuth();
    const { i18n } = useTranslation();
    const title = props.title;

    const defaultDisplay = viewerDefaultDisplay;

    React.useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await axios.get(WEBSITE_URL, {
                    params: {
                        username: username,
                        art: i18n.language, // specify the entry you want to have
                        title: title, // specify the column you want to retrieve
                    },
                });
                setContent(response.data.entry.content);
            } catch (error) {
                console.error(error);
            }
        };
        fetchContent();
    }, [username, i18n.language, title]);

    return (
        <span>{content === editorDefaultEntries.paragraph ? defaultDisplay.paragraph : content}</span>
    );
};

export default FetchContent;



